<template>
  <div class="container" @scroll="handleScroll">
    <i @click="handle_print" class="material-icons print-icon">print</i>
    <div ref="wrapper"  class="wrapper" id="wrapper">
      <div class="steps noprint" data-html2canvas-ignore="true">
        <ul
          style="height: 100%; display: flex; text-decoration: none; list-style-type: none; justify-content: space-between; align-items: center;"
        >
          <li v-if="position == 'pi'" style=" font-size: 1.2rem; color:red;">
            פרטים אישיים
          </li>
          <li v-else style=" font-size: 1.2rem;" @click="handleScrollTo('pi')">
            פרטים אישיים
          </li>
          <li
            v-if="position == 'mishmaat'"
            style=" font-size: 1.2rem; color:red;"
          >
            משמעת
          </li>
          <li
            v-else
            style=" font-size: 1.2rem;"
            @click="handleScrollTo('mishmaat')"
          >
            משמעת
          </li>
          <li v-if="position == 'nb'" style=" font-size: 1.2rem; color:red;">
            נהלי בטיחות
          </li>
          <li v-else style=" font-size: 1.2rem;" @click="handleScrollTo('nb')">
            נהלי בטיחות
          </li>
          <li v-if="position == 'ta'" style=" font-size: 1.2rem; color:red;">
            תנאי העסקה
          </li>
          <li v-else style=" font-size: 1.2rem;" @click="handleScrollTo('ta')">
            תנאי העסקה
          </li>
          <li v-if="position == 'ms'" style=" font-size: 1.2rem; color:red;">
            חתימת מנהל
          </li>
          <li v-else style=" font-size: 1.2rem;" @click="handleScrollTo('ms')">
            חתימת מנהל
          </li>
          <li v-if="position == 'tb'" style=" font-size: 1.2rem; color:red;">
            ביטחון
          </li>
          <li v-else style=" font-size: 1.2rem;" @click="handleScrollTo('tb')">
            ביטחון
          </li>
        </ul>
      </div>
      <div class="header">
        <div class="for-write" style="display: flex;">
          <p style="margin-left: 15px;">
            <span class="text">מספר עובד</span
            ><span dir="rtl">________</span>&rlm;
          </p>
          <p style="margin-left: 15px;">
            <span class="text">מספר לקוח</span
            ><span dir="rtl">________</span>&rlm;
          </p>
          <p style="margin-left: 15px;">
            <span class="text">מספר מסוף </span
            >
            <span v-if="!in_process" dir="rtl">________</span>&rlm;
            <span v-if="in_process" dir="rtl" style="border-bottom: 2px dashed lightgray;">{{values.masof_number}}</span>&rlm;
          </p>
        </div>
        <div
          class="logo"
          style="width: 100%; text-align: center; margin-top:10px;"
        >
          <img
            v-if="!branche.includes('ביוטי פארם')"
            src="@/assets/images/rosman-market.png"
            alt=""
            style="width: 200px;"
          />
          <img
            v-else
            src="@/assets/images/buty_pharm.png"
            alt=""
            style="width: 200px;"
          />
        </div>
        <div  class="welcome noprint">
          <h3 style="word-wrap:normal;" class="text">
            ברוכים הבאים לקבוצת רוסמן<span dir="rtl">!</span>&rlm;
          </h3>
          <p class="text">
            להלן מידע חיוני עלייך לדעת בכל זמן שהנך עובד בחברתנו אנא קרא היטב את
            המידע וההוראות הללו
          </p>
          <p class="text">
            ופעל על פי הנחיות כל זמן בו אתה עובד על מנת למנוע אי הבנות ופגיעה
            מיותרת בך ובחבריך לעבודה
          </p>
        </div>
        <div class="tofes-klitat-oved">
          <h3 class="text" style="font-weight:500;">הסכם עבודה</h3>
          <p class="text"> {{new Date().toLocaleDateString('he')}}</p>
          <p class="text">{{ values.privateName }} {{ values.lastName }}</p>
        </div>
      </div>
      <!-- פרטים אישיים -->
      <div class="content" id="pi">
        <h3 class="text" style="text-align:center;">פרטים אישיים</h3>
        <hr />
        <div class="rows">
          <div class="field">
            <p class="text">שם הסניף</p>
            <p v-if="in_process" style="color:red;">{{ branche }}</p>
            <InputText
              v-else
              type="text"
              :placeholder="branche"
              disabled
              style="width:100%;"
            />
          </div>
          <div class="field">
            <p class="text">תפקיד מיועד</p>
            <p v-if="in_process" style="color:red;">{{ values.role }}</p>
            <Dropdown
              v-else
              v-model="values.role"
              :options="roleOptions"
              :placeholder="values.role"
              style="width:100%; height:35px;"
            />
          </div>
          <div class="field">
            <p class="text">תאריך תחילת עבודה</p>
            <p v-if="in_process" style="color:red;">{{ new Date(values.startWorkingDate).toLocaleDateString('he') }}</p>
            <input
              v-else
              type="date"
              v-model="values.startWorkingDate"
              :placeholder="values.startWorkingDate"
            />
          </div>
          <div class="field">
            <p class="text">שם פרטי</p>
            <p v-if="in_process" style="color:red;">{{ values.privateName }}</p>
            <InputText
              v-else
              type="text"
              v-model="values.privateName"
              :placeholder="values.privateName"
              style="width:100%;"
            />
          </div>
          <div class="field">
            <p class="text">שם משפחה</p>
            <p v-if="in_process" style="color:red;">{{ values.lastName }}</p>
            <InputText
              v-else
              type="text"
              v-model="values.lastName"
              :placeholder="values.lastName"
              style="width:100%;"
            />
          </div>
          <div class="field">
            <p class="text">מס' ת.ז/דרכון</p>
            <p v-if="in_process" style="color:red;">{{ values.idNumber }}</p>
            <InputText
              v-else
              type="text"
              v-model="values.idNumber"
              :placeholder="values.idNumber"
              style="width:100%;"
            />
          </div>
          <div class="field">
            <p>
              <span class="text">דרך מי הגיע המועמד</span
              ><span dir="rtl">?</span>&rlm;
            </p>
            <p v-if="in_process" style="color:red;">{{ values.hr }}</p>
            <Dropdown
              v-else
              v-model="values.hr"
              :options="hrOptions"
              :placeholder="values.hr"
              style="width:100%; height:35px"
            />
          </div>
          <div class="field">
            <p class="text">סוג האזרחות והמעמד בישראל</p>
            <p v-if="in_process" style="color:red;">{{ values.citizenType }}</p>
            <Dropdown
              v-else
              v-model="values.citizenType"
              :options="citizenTypeOptions"
              :placeholder="values.citizenType"
              style="width:100%; height:35px;"
            />
          </div>
          <div
            v-if="
              values.citizenType == 'תושב ארעי' ||
                values.citizenType == 'אשרת עבודה בדרכון'
            "
            class="field"
          >
            <p class="text">תוקף אישור העבודה/ת.אירעית</p>
            <p v-if="in_process" style="color:red;">{{ values.validityPermission }}</p>
            <input
              v-else
              type="date"
              v-model="values.validityPermission"
              :placeholder="values.validityPermission"
            />
          </div>
        </div>
        <div v-if="values.hr=='חבר מביא חבר'" class="field">
          <p class="text">שם החבר/ה הממליץ/ה</p>
            <p v-if="in_process" style="color:red;">{{ values.friend_recommend || 'לא צויין' }}</p>
            <InputText
              v-else
              type="text"
              v-model="values.friend_recommend"
              :placeholder="values.friend_recommend"
              style="width:100%;"
            />
        </div>
        <div class="field" v-if="!in_process">
          <p>
            <span class="text" style="color:red; font-size:20px;">מספר מסוף תזמון שעות</span>
          </p>
           <InputNumber style="width:100%;" inputId="withoutgrouping" v-model="values.masof_number" mode="decimal" :useGrouping="false" />
        </div>
        <div class="field">
          <p>
            <span class="text">האם יש לך קרן פנסיה פעילה</span
            ><span dir="rtl">?</span>&rlm;
          </p>
          <small class="text"
            >יש לנו הסכם מיטיב עם חברת הפנסיה "מגדל". באפשרותך לבחור בכל קרן
            פנסיה אחרת, לרבות פנסיית ברירת מחדל</small
          >
          <p v-if="in_process" style="color:red;">{{ values.pensia }}</p>
          <Dropdown
            v-else
            v-model="values.pensia"
            :options="pensiaOptions"
            :placeholder="values.pensia"
            style="width:100%"
          />
        </div>
        <div class="field" style="margin-top:15px; width: 100%;">
          <p class="text">
            במידה ויש לך קרן פנסיה פעילה באחריותך לפנות תוך 90 ימים למשרדים עם
            טופס מעבר מעסיק<span dir="rtl">:</span>&rlm;
          </p>
          <SignaturePad idName="paint1" sketchName="sketch1" />
        </div>
        <p class="text" style="margin-top:15px; text-align:center;">
          ברוסמן תשלום המשכורת מתבצעת דרך העברה בנקאית
          <strong>בחר את מס' הבנק, סניף וחשבון</strong>
        </p>
        <div class="rows">
          <div class="field">
            <p class="text">שם הבנק</p>
            <p v-if="in_process" style="color:red;">{{ values.bankName }}</p>
            <Dropdown
              v-else
              v-model="values.bankName"
              :options="bankNameOptions"
              :placeholder="values.bankName"
              style="width:100%; height:35px;"
            />
          </div>
          <div class="field">
            <p class="text">מס' סניף</p>
            <p v-if="in_process" style="color:red;">{{ values.numberBankBrnache }}</p>
            <InputText
              v-else
              type="text"
              v-model="values.numberBankBrnache"
              :placeholder="values.numberBankBrnache"
              style="width:100%"
            />
          </div>
          <div class="field">
            <p class="text">מס' חשבון</p>
            <p v-if="in_process" style="color:red;">{{ values.bankAccount }}</p>
            <InputText
              v-else
              type="text"
              v-model="values.bankAccount"
              :placeholder="values.bankAccount"
              style="width:100%"
            />
          </div>
          <div class="field">
            <p class="text">מצב משפחתי</p>
            <p v-if="in_process" style="color:red;">{{ values.familyStatus }}</p>
            <Dropdown
              v-else
              v-model="values.familyStatus"
              :options="familyStatusOptions"
              :placeholder="values.familyStatus"
              style="width:100%; height:35px;"
            />
          </div>
          <div class="field">
            <p class="text">תאריך לידה</p>
            <p v-if="in_process" style="color:red;">{{ values.birthDate }}</p>
            <input
              v-else
              type="date"
              v-model="values.birthDate"
              :placeholder="values.birthDate"
            />
          </div>
          <div class="field">
            <p class="text">תאריך עלייה לישראל</p>
            <p v-if="in_process" style="color:red;">{{ values.aliyaDate }}</p>
            <input
              v-else
              type="date"
              v-model="values.aliyaDate"
              :placeholder="values.aliyaDate"
            />
          </div>
          <div class="field">
            <p>יישוב</p>
            <p v-if="in_process" style="color:red;">{{ values.city }}</p>
            <InputText
              v-else
              type="text"
              v-model="values.city"
              :placeholder="values.city"
              style="width:100%;"
            />
          </div>
          <div class="field">
            <p>רחוב</p>
            <p v-if="in_process" style="color:red;">{{ values.street }}</p>
            <InputText
              v-else
              type="text"
              v-model="values.street"
              :placeholder="values.street"
              style="width:100%;"
            />
          </div>
          <div class="field">
            <p class="text">מס' בית</p>
            <p v-if="in_process" style="color:red;">{{ values.houseNumber }}</p>
            <InputText
              v-else
              type="text"
              v-model="values.houseNumber"
              :placeholder="values.houseNumber"
              style="width:100%;"
            />
          </div>
          <div class="field">
            <p class="text">מס' דירה</p>
            <p v-if="in_process" style="color:red;">{{ values.apartmentNumber }}</p>
            <InputText
              v-else
              type="text"
              v-model="values.apartmentNumber"
              :placeholder="values.apartmentNumber"
              style="width:100%;"
            />
          </div>
          <div class="field">
            <p>ת.ד</p>
            <p v-if="in_process" style="color:red;">{{ values.tadDoar }}</p>
            <InputText
              v-else
              type="text"
              v-model="values.tadDoar"
              :placeholder="values.tadDoar"
              style="width:100%;"
            />
          </div>
          <div class="field">
            <p>מיקוד</p>
            <p v-if="in_process" style="color:red;">{{ values.mikud }}</p>
            <InputText
              v-else
              type="text"
              v-model="values.mikud"
              :placeholder="values.mikud"
              style="width:100%;"
            />
          </div>
        </div>
        <div class="col-3">
          <div class="field">
            <p class="text">טלפון סלולרי</p>
            <p v-if="in_process" style="color:red;">{{ values.phoneNumber }}</p>
            <InputText
              v-else
              type="text"
              v-model="values.phoneNumber"
              :placeholder="values.phoneNumber"
              style="width:100%;"
            />
          </div>
          <div class="field">
            <p>
              <span class="text" style="white-space: nowrap;">הכנסה נוספת</span
              ><span dir="rtl">?</span>&rlm;
            </p>
            <p v-if="in_process" style="color:red;">{{ values.incomingFromOtherPlace }}</p>
            <Dropdown
              v-else
              v-model="values.incomingFromOtherPlace"
              :options="incomingFromOtherPlaceOptions"
              :placeholder="values.incomingFromOtherPlace"
              style="width:100%; height:35px;"
            />
          </div>
          <div class="field">
            <p class="text">ציין מהו מקור ההכנסה</p>
            <p v-if="in_process" style="color:red;">{{ values.incomingPlace }}</p>
            <InputText
              v-else
              type="text"
              v-model="values.incomingPlace"
              :placeholder="values.incomingPlace"
              style="width:100%;"
            />
          </div>
        </div>
        <div class="field">
            <p style="line-height: 0;" class="text"> 
              ברוסמן תלוש השכר נשלח לדוא"ל 
            </p>
            <br>
            <p  style="line-height: normal;" class="text">אני נותן בזה את הסכמתי לקבל את תלוש השכר בעד עבודתי באמצעות שליחה לכתובת הדוא"ל הפרטי שלי</p>
            <p v-if="in_process" style="color:red;">{{ values.email }}</p>
            <InputText
              v-else
              type="text"
              v-model="values.email"
              :placeholder="values.email"
              style="width:100%;"
            />
          </div>
        <div class="col-2">
          <div class="field">
            <p class="text">קופת חולים</p>
            <p v-if="in_process" style="color:red;">{{ values.kupatHolim }}</p>
            <Dropdown
              v-else
              v-model="values.kupatHolim"
              :options="kupatHolimOptions"
              :placeholder="values.kupatHolim"
              style="width:100%"
            />
          </div>
          <div class="field">
            <p class="text">
              <span class="text">האם יש לך ילדים עד גיל 18</span
              ><span dir="rtl">?</span>&rlm;
            </p>
            <p v-if="in_process" style="color:red;">{{ values.isChildren }}</p>
            <Dropdown
              v-else
              v-model="values.isChildren"
              :options="childrenOptions"
              :placeholder="values.isChildren"
              style="width:100%"
            />
          </div>
        </div>
        <div v-if="values.isChildren == 'כן' && values.familyStatus!='נשוי/אה'" class="col-3">
          <div class="field">
            <p class="text">
              <span class="text">האם משלם/ת מזונות עבור ילדיך</span>
              <span dir="rtl">?</span>&rlm;
            </p>
            <p v-if="in_process" style="color:red;">{{ values.mezonot }}</p>
            <Dropdown
              v-else
              v-model="values.mezonot"
              :options="childrenOptions"
              :placeholder="values.mezonot"
              style="width:100%"
            />
          </div>
          <div class="field">
            <p class="text">
              <span class="text">האם ילדיך בחזקה הבלעדית</span>
              <span dir="rtl">?</span>&rlm;
            </p>
            <p v-if="in_process" style="color:red;">{{ values.children_owner }}</p>
            <Dropdown
              v-else
              v-model="values.children_owner"
              :options="childrenOptions"
              :placeholder="values.children_owner"
              style="width:100%"
            />
          </div>
          <div class="field">
            <p class="text">
              <span class="text">האם מנהל משק בית עם יחיד/ה אחר</span>
              <span dir="rtl">?</span>&rlm;
            </p>
            <p v-if="in_process" style="color:red;">{{ values.house_hold_with_someone }}</p>
            <Dropdown
              v-else
              v-model="values.house_hold_with_someone"
              :options="childrenOptions"
              :placeholder="values.house_hold_with_someone"
              style="width:100%"
            />
          </div>
        </div>
        <div class="rows">
          <div class="field">
            <p class="text">צילום תעודת זהות צד א' + ספח</p>
            <input
              type="file"
              style="border: none;"
              @change="handleIdPicPartA"
            />
          </div>
          <div class="field">
            <p class="text">צילום תעודת זהות צד ב'</p>
            <input
              type="file"
              style="border: none;"
              @change="handleIdPicPartB"
            />
          </div>
          <div class="field">
            <p class="text">צילום פרטי חשבון בנק</p>
            <input
              type="file"
              style="border: none;"
              @change="handleAccountBankPic"
            />
          </div>
        </div>
        <div class="field" style="margin-top:15px; width: 100%;">
          <p class="text">חתימת העובד בדבר פרטים אישיים</p>
          <SignaturePad idName="paint2" sketchName="sketch2" />
        </div>

        <div v-if="values.isChildren == 'כן'" class="col-4 before">
          <template v-for="(children, index) in values.childrens" :key="index">
            <div class="field">
              <p class="text">
                שם מלא של הילד
                <span
                  style="color:red; cursor:pointer;"
                  @click="handleDeleteChild(index)"
                  >הסר</span
                >
              </p>
              <p v-if="in_process" style="color:red;">{{ values.childrens[index].name }}</p>
              <InputText
                v-else
                type="text"
                v-model="values.childrens[index].name"
                style="width:100%;"
                :placeholder="values.childrens[index].name"
              />
            </div>
            <div class="field">
              <p class="text">תאריך לידה של הילד</p>
              <p v-if="in_process" style="color:red;">{{ new Date(values.childrens[index].birthDate).toLocaleDateString('he') }}</p>
              <input
                v-else
                type="date"
                v-model="values.childrens[index].birthDate"
                :placeholder="values.childrens[index].birthDate"
              />
            </div>
            <div class="field">
              <p class="text">תז של הילד</p>
              <p v-if="in_process" style="color:red;">{{ values.childrens[index].id }}</p>
              <InputText
                v-else
                type="text"
                v-model="values.childrens[index].id"
                style="width:100%;"
                :placeholder="values.childrens[index].id"
              />
            </div>
            <div class="field">
              <Button
                class="p-button-rounded p-button-secondary"
                icon="pi pi-plus"
                @click="handleAddChildren"
                data-html2canvas-ignore="true"
              />
            </div>
          </template>
        </div>
      </div>
      <!-- משמעת -->
      <div class="content before" id="mishmaat">
        <h3
          style="text-align:center; color:red; text-decoration:underline; margin:25px 0;"
          class="text"
        >
          טופסי קליטת עובד חדש ברשת רוסמן <span dir="rtl">-</span>&rlm; משמעת
        </h3>
        <hr />
        <br />
        <p class="text">שלום וברוכים הבאים לרשת רוסמן</p>
        <br />
        <p class="text">
          להלן מידע חיוני עלייך לדעת בכל זמן שהנך עובד בחברתנו אנא קרא היטב את
          המידע וההוראות הללו
        </p>
        <p class="text">
          ופעל על פי הנחיות כל זמן בו אתה עובד על מנת למנוע אי הבנות ופגיעה
          מיותרת בך ובחבריך לעבודה
        </p>
        <br /><br />
        <p style="font-weight:bold;" class="text">משמעת וסדרי עבודה</p>
        <br />
        <p>
          א<span dir="rtl">.</span>
          <span class="text"
            >יש להגיע לעבודה בשעה המוסכמת עם מנהל העבודה הישיר ו/או לפי המשמרות
            המסודרות בחנות</span
          ><span dir="rtl">.</span>
        </p>
        <br />
        <p>
          ב<span dir="rtl">.</span>
          <span class="text">בתחילת העבודה ובסיומה יש להדפיס כרטיס נוכחות</span
          ><span dir="rtl">.</span>&rlm;
        </p>
        <p class="text">
          בסניפים בהם ישנה מערכת טביעת אצבע החתמת הכרטיס תבוצע על ידי מערכת
          זו<span dir="rtl">.</span>&rlm;
        </p>
        <br />
        <p>
          ג<span dir="rtl">.</span> <span class="text">אין לאחר לעבודה</span
          ><span dir="rtl">.</span>&rlm;
        </p>
        <p class="text">
          עובד שיודע כי בלית ברירה יאחר לעבודה חובה עליו לדווח למנהל/סגן הסניף
          מוקדם ככל האפשר על האיחור<span dir="rtl">.</span>&rlm;
        </p>
        <p class="text">
          איחור ללא דיווח ייחשב כהפרת משמעת חמורה<span dir="rtl">.</span>&rlm;
        </p>
        <br />
        <p>
          ד<span dir="rtl">.</span>&rlm;
          <span class="text"
            >במקרה שהעובד אינו יכול להגיע לעבודה מכל סיבה שהיא לרבות סיבות
            בריאותיות</span
          ><span dir="rtl">,</span>&rlm;
          <br />
          <span class="text"
            >מילואים וכו' חובה עליו לדווח למנהל/סגן הסניף מוקדם</span
          >
          <br />
          <span class="text">ככל האפשר על ההיעדרות </span
          ><span dir="rtl">,</span>&rlm;
          <span class="text">היעדרות ללא דיווח תיחשב כהפרת משמעת חמורה</span
          ><span dir="rtl">.</span>&rlm;
        </p>
        <br />
        <p>
          ה<span dir="rtl">.</span>&rlm;
          <span class="text"
            >במידה והעובד מעוניין ביום חופש עליו להודיע למנהל הסניף ולקבל אישור
            ליום החופש</span
          ><span dir="rtl">.</span>&rlm;
          <br />
          <span class="text"
            >בהחלט ייתכנו מצבים שלא יהיה ניתן לאשר את יום החופש בתאריך אותו רצה
            העובד בשל אילוצי הרשת</span
          ><span dir="rtl">.</span>&rlm;
          <br />
          <span class="text">היעדרות ללא דיווח תיחשב כהפרת משמעת חמורה</span
          ><span dir="rtl">.</span>&rlm;
        </p>
        <br />
        <p>
          ו<span dir="rtl">.</span>
          <span class="text"
            >אין לעבוד במקום נוסף במקביל לעבודה בפארמה דיל וברשת מקס פארם ללא
            אישור ממנכ"ל הרשת</span
          ><span dir="rtl">.</span>
        </p>
        <br />
        <p>
          ז<span dir="rtl">.</span>
          <span style="font-weight:bold;" class="text"> בגדי עבודה</span>
        </p>
        <ul style="padding: 10px 40px 10px 25px; list-style-type: none;">
          <li>
             <span class="text">לכל עובד בחברת שיווק רוסמן</span><span dir="rtl"> (</span><span class="text">חנויות</span><span dir="rtl">) </span>
             <span class="text">יסופק בגדי עבודה בהתאם לתפקידו</span>
             <br>
             <span class="text">שהינם חלק בלתי נפרד מהופעתו לעבודה</span><span dir="rtl">.</span>
          </li>
          <br>
          <li>
            <span class="text">עליך נדרש לחתום על קבלת פרטי הלבוש</span><span dir="rtl"> (</span><span class="text">בגדיים/נעליים</span><span dir="rtl">) </span>
            <span class="text">והנך מצהיר ומתחייב</span>
            <br>
            <span class="text">להשתמש בציוד אך ורק לצורך פעילותך ועבודתך אצל המעסיק</span>
            <span dir="rtl"> (</span><span class="text">שימוש סביר ורגיל</span><span dir="rtl">).</span>
          </li>
          <br>
          <li>
            <span class="text">בסיום עבודתך מסיבה כלשהי</span><span dir="rtl"> (</span><span class="text">התפטרות/פיטורים</span><span dir="rtl">) </span>
            <span class="text">הנך מחויב להשיב את הציוד למעסיק שעליהם חתמת</span><span dir="rtl">.</span>
          </li>
          <br>
          <li>
            <span class="text">בעת עזיבתך וכחלק מהליך גמר חשבון</span><span dir="rtl"> (</span><span class="text">התפטרות/פיטורים</span><span dir="rtl">) </span>
            <span class="text">תבוצע ביקורת מצאי בגדים</span>
            <br>
            <span dir="rtl"> (</span><span class="text">טופס הזמנת ביגוד שהעובד חתם</span><span dir="rtl">) </span>
            <span class="text">אל מול מצאי בגדים שהוחזר</span><span dir="rtl">.</span>
          </li>
          <br>
          <li>
            <span class="text">אם וכאשר ימצא פער הנך תחויב כספית בהתאם לרשימה</span>
            <br>
            <span class="text">המופיעה מטה והחוב ינוכה לך משכרך האחרון</span><span dir="rtl">.</span>
          </li>
          <br>
          <li>
            <span class="text">נעל בטיחות 3/4 MINA S3 SRC – 115 ₪</span>
          </li>
          <li>
            <span class="text">פולו גבר ש.ק שחור – 39 ₪</span>
          </li>
          <li>
            <span class="text">כובע בנדנה אדום – 16 ₪</span>
          </li>
          <li>
            <span class="text">פוטר ניקי שחור/לבן – 56 ₪</span>
          </li>
          <li>
            <span class="text">טוניקה נשים ש.ק שחור – 54 ₪</span>
          </li>
          <li>
            <span class="text">מעיל טבח ש.ק עם לחצניות שחור – 61 ₪</span>
          </li>
          <li>
            <span class="text">פליז גבר ש.א שחור – 57 ₪</span>
          </li>
          <br>
          <p class="before"></p>
          <p style="margin:25px 0;"></p>
          <li>
            <span class="text" style="font-weight:bold;">יש להגיע בלבוש תקני וייצוגי הכולל</span><span dir="rtl">:</span>
            <span class="text"> מכנס ארוך</span><span dir="rtl">, </span><span class="text">חולצת עבודה של הרשת נקייה</span>
            <span dir="rtl">, </span><span class="text">נעלי עבודה סגורות</span><span dir="rtl">,</span>
            <br>
            <span class="text">לנשים איפור עדין</span><span dir="rtl">, </span><span class="text">לגברים שיער פנים מגולח ולעובדי מעדנייה ציפורניים טבעיות/מלאכותיות יש לגזוז</span>
            <span dir="rtl">.</span>
          </li>
          <br>
          <li>
           <span style="font-weight:bold;" class="text">** ביגוד מתאים של רשת רוסמן יימסר לעובד בהתאם למחלקה בו הוא משובץ</span>
          </li>
        </ul>
        <br />
        <p>
          ח<span dir="rtl">.</span>&rlm;
          <span class="text">העובד יעבוד בכל משמרת אליה שובץ בהתאם לסידור העבודה ובהתאם לשעות, אשר נקבעו בסידור העבודה<span dir="rtl">.</span>&rlm;</span>
          <br />
          <span class="text">
           במסגרת שעות אלו יהיה לעובד זכאות לקבל הפסקה רגילה בהתאם לחוק<span dir="rtl">.</span>&rlm;
          </span
          >
          <br>
          <span class="text">
            הפסקות במהלך המשמרת יש לתאם מול מנהל/סגן הסניף<span dir="rtl">.</span>&rlm;
          </span>
            
        </p>
        <br />
        <p>
          ח.א<span dir="rtl">.</span>&rlm; <span class="text">חברת שיווק רוסמן בע"מ הינה חברה העוסקת במכירות מזון לא כשר</span>
          <br>
          <span class="text">וככזאת</span><span dir="rtl">,</span>&rlm;<span class="text">החליטה החברה כהחלטה אסטרטגית לפעול 7 ימים בשבוע כולל  שבתות וערבי חג</span> <span dir="rtl">(</span>&rlm;<span class="text">למעט יום כיפור</span><span dir="rtl">).</span>&rlm;
          <br>
          <span class="text">בהתאם לאמור לעיל ועל פי הודעתו של העובד</span><span dir="rtl">,</span>&rlm;<span class="text"> יום המנוחה השבועי שלו הינו יום</span><span dir="rtl">:</span>&rlm;
          <span class="text" style="font-weight:bold" :style="[values.rest_day=='יום א' ? {'color':'red'} : {'color':'black'}]">א</span><span dir="rtl">/</span>&rlm;
          <span class="text" style="font-weight:bold" :style="[values.rest_day=='יום ו' ? {'color':'red'} : {'color':'black'}]">ו</span><span dir="rtl">/</span>&rlm;
          <span class="text" style="font-weight:bold" :style="[values.rest_day=='יום שבת' ? {'color':'red'} : {'color':'black'}]">שבת</span>
          <br>
          <span class="text" style="font-weight:bold">**בסניפים שאינם פתוחים בשבת, יהיה יום המנוחה, יום שבת</span><span dir="rtl">.</span>&rlm;
          <br>
          <span class="text">הגמול הינו בהתאם לקבוע בחוק</span><span dir="rtl"> (</span>&rlm;<span class="text">תשי"א 1951</span><span dir="rtl">).</span>&rlm;
          <br>
        </p>
        <div v-if="!in_process" class="field">
            <p>נא לבחור את יום מנוחת העובד</p>
            <Dropdown
            v-model="values.rest_day"
            :options="rest_days_options"
            :placeholder="values.rest_day"
            style="width:100%"
          />
        </div>
        <br>
        <p>
          ט<span dir="rtl">.</span>&rlm;
          <span class="text"
            >ניהול רישום דיווח נוכחות במשמרת יעשה באמצעות העברת כרטיס מגנטי
            בלבד</span
          >
          <span dir="rtl">.</span>&rlm;
        </p>
        <br />
        <p>
          י<span dir="rtl">.</span>&rlm;
          <span class="text">חל איסור מוחלט לדבר בטלפון הנייד</span
          ><span dir="rtl">,</span>&rlm;
          <span class="text"
            >לאכול לשתות או לעשן בתחום המחלקה בה משובץ העובד</span
          >
          <span dir="rtl">.</span>&rlm;
          <span class="text">לשם כך יינתנו הפסקות</span>
          <span dir="rtl">.</span>&rlm;
        </p>
        <br />
        <p>
          יא<span dir="rtl">.</span>&rlm;
          <span class="text"
            >חל איסור מוחלט לשתות שתייה חריפה אלכוהולית מכל סוג שהוא במהלך שעות
            העבודה</span
          >
          <span dir="rtl">!</span>&rlm;
          <span class="text">לרבות חגיגות ימי הולדת לעובדים</span>
          <span dir="rtl">, </span>&rlm;<span class="text"
            >משתה או אירוע כזה או אחר במהלך שעות העבודה</span
          >
          <span dir="rtl">.</span>&rlm;
        </p>
        <br />
        <p>
          יב<span dir="rtl">.</span>&rlm;
          <span class="text"
            >יש להישמע בכל עת להוראות מנהל/סגן הסניף ולהתנהג בצורה הולמת וראויה
            כלפי שאר העובדים והלקוחות</span
          ><span dir="rtl">.</span>&rlm;
          <br />
          <span class="text"
            >רוסמן שיווק מתנהגת באפס סובלנות כלפי מקרים בהם עובד זלזל או הטריד
            בכל</span
          >
          <br />
          <span class="text">צורה שהיא לקוחות או עובדים אחרים</span
          ><span dir="rtl">.</span>&rlm;
        </p>
        <br />
        <p>
          יג<span dir="rtl">.</span>&rlm;
          <span class="text"
            >במידה ועובד חש מוטרד או נפגע בכל צורה שהיא יפנה העובד למנהל
            הסניף</span
          ><span dir="rtl">.</span>&rlm;
          <span class="text"
            >במידה והבעיה אינה נפתרה יוכל לפנות לאחראי מטעם ההנהלה הכפוף למנכ"ל
            הרשת</span
          >
          <span dir="rtl">.</span>&rlm;
        </p>
        <br />
        <p>
          יד<span dir="rtl">.</span>&rlm;
          <span class="text"
            >במקרים שעובד חש נפגע מהטרדה מינית עליו לפנות במידי לאחראית על הנושא
            מטעם רשת רוסמן</span
          >
          <br />
          <span class="text">הגברת אירנה סורוב בטלפון 046933558</span>
        </p>
        <br />
        <p>
          טו<span dir="rtl">.</span>&rlm;
          <span class="text"
            >בכל בעיה אישית/כללית שהעובד מעוניין להעלות יפנה העובד למנהל
            הסניף</span
          ><span dir="rtl">.</span>&rlm;
          <br />
          <span class="text"
            >במידה והבעיה אינה נפתרה יוכל לפנות לאחראי מטעם ההנהלה הכפוף למנכ"ל
            הרשת</span
          ><span dir="rtl">.</span>&rlm;
        </p>
        <br />
        <p>
          טז<span dir="rtl">.</span>&rlm;
          <span class="text">רוסמן שיווק הינו כבית שני לכם ומקור פרנסתכם</span
          ><span dir="rtl">,</span>&rlm;
          <span class="text">אנא השתדלו ככל האפשר להימנע מבזבוז מיותר של</span>
          <br />
          <span class="text">חומרי גלם</span><span dir="rtl">,</span>&rlm;
          <span class="text">מזון או כל ציוד אחר הניתן לכם לצורך עבודתכם</span
          ><span dir="rtl">.</span>&rlm;
          <br />
          <span class="text">יש לשמור על שלמות הציוד</span
          ><span dir="rtl">,</span>&rlm;
          <span class="text">לדווח על כל תקלה בו ואין להשתמש בציוד זה </span>
          <br />
          <span class="text"
            >לעבודות פרטיות שאינן קשורות לעבודתכם ברשת רוסמן</span
          ><span dir="rtl">.</span>&rlm;
        </p>
        <br />
        <p style="font-weight:bold;">
          <span class="text">נותני שירות ואנשים חיצוניים לרשת רוסמן</span
          ><span dir="rtl">:</span>&rlm;
        </p>
        <br />
        <p>
          יז<span dir="rtl">.</span>&rlm;
          <span class="text"
            >אין רשות להכניס אף אדם לחנות בכדי לבצע תיקונים/ביקורת/אחזקה ללא
            אישור וידיעת ההנהלה</span
          ><span dir="rtl">.</span>&rlm;
        </p>
        <br />
        <p>
          יח<span dir="rtl">.</span>&rlm;
          <span class="text"
            >במידה ומגיע נותן שירות/ביקורת בחנות למשל עירייה/כיבוי אש/וטרינר
            וכו</span
          >
          <br />
          <span class="text"
            >יש לבקש בנימוס תעודה מזהה לצורך זיהוי האדם ולדווח להנהלה</span
          ><span dir="rtl">.</span>&rlm;
        </p>
        <br />
        <p>
          יט<span dir="rtl">.</span>&rlm;
          <span class="text"
            >אין לתת לאף אדם לבצע שירות בחנות ללא קבלת אישור מההנהלה לפני
            כן</span
          ><span dir="rtl">.</span>&rlm;
        </p>
        <br />
        <p>
          כ<span dir="rtl">.</span>&rlm;
          <span class="text"
            >במהלך הבדיקה/שירות ילווה איש צוות של החנות את נותן השירות</span
          ><span dir="rtl">.</span>&rlm;
        </p>
        <br />
        <p>
          כא<span dir="rtl">.</span>&rlm;
          <span class="text"
            >בתום הבדיקה/ביקורת יש לקבל מסמך המפרט את אשר בוצע בחנות</span
          ><span dir="rtl">.</span>&rlm;
          <br />
          <span class="text"
            >לאחר קבלת המסמך יש לחתום עם חותמת הסניף ולפקסס מידית למשרדי
            החברה</span
          ><span dir="rtl">.</span>&rlm;
        </p>
        <br /><br />
        <p class="before"></p>
        <p style="margin:25px 0;"></p>
        <p style="font-weight:bold;" class="text">
          קופאיות<span dir="rtl">:</span>&rlm;
        </p>
        <br />
        <p>
          כב<span dir="rtl">.</span>&rlm;
          <span class="text"
            >הקופאית תנהג באחריות בכל זמן עבודתה על הקופה מתוך הבנה כי היא עוסקת
            בעבודה</span
          >
          <br />
          <span class="text">הכרוכה במגע עם סכומי כסף גדולים</span
          ><span dir="rtl">.</span>&rlm;
        </p>
        <br />
        <p>
          כג<span dir="rtl">.</span>&rlm;
          <span class="text"
            >לא תיטוש את העמדה מבלי לקבל אישור לכך ובכל מקרה תדאג כי העמדה מוגנת
            דיו מפני
          </span>
          <br />
          <span class="text"
            >גניבות ובכל מקרה ובכל שלב אין במגירת הקופה מפתח אשר ניתן בעזרתו
            לפתוח את מגירת הכסף</span
          ><span dir="rtl">.</span>&rlm;
        </p>
        <br />
        <p>
          כד<span dir="rtl">.</span>&rlm;
          <span class="text"
            >בזמן תקלת תקשורת בעמדת הקופה‏ אסור בתכלית האיסור לקבל ולהעביר
            עסקאות המשולמות</span
          >
          <br />
          <span class="text"
            >באמצעות כרטיס אשראי אשר לא מוטבעים עליו מספר הכרטיס ושם הלקוח</span
          ><span dir="rtl">.</span>&rlm;
          <br />
          <span class="text"
            >במידה ותחרוג הקופאית מנוהל זה רשאית חברת רוסמן לגבות ממשכורת
            הקופאית את הסכום המלא שבוצע</span
          >
          <br />
          <span class="text"
            >בעסקה עם כרטיס אשראי ללא הפרטים הנ"ל בזמן תקלת תקשורת</span
          ><span dir="rtl">.</span>&rlm;
        </p>
        <br />
        <p style="font-weight:bold;">
          כה<span dir="rtl">.</span>&rlm;
          <span class="text"
            >חל איסור מוחלט למכירת משקאות המכילים אלכוהול לכל אדם מתחת לגיל
            18</span
          ><span dir="rtl">!</span>&rlm;
          <br />
          <span class="text">חובת הקופאית לבדוק ת"ז</span
          ><span dir="rtl">,</span>&rlm;
          <span class="text"
            >רישיון נהיגה או כל פרט זיהוי בטרם מכירת אלכוהול</span
          >
          <br />
          <span class="text">לאדם וזאת בכדי לוודא כי הינו מעל גיל 18</span>
          <span class="text">הגיל המוגדר בחוק למכירת משקאות אלכוהוליים</span
          ><span dir="rtl">.</span>&rlm;
        </p>
        <br /><br />
        <p style="font-weight:bold;">
          <span class="text"
            >חריגה מנוהל זה תגרום להזמנה לשימוע מידי ואף בסכנת פיטורין</span
          ><span dir="rtl">.</span>&rlm;
        </p>

        <p style="font-weight:bold;">
          <span class="text">הצהרת העובד בנושא משמעת וסדרי עבודה</span
          ><span dir="rtl">:</span>&rlm;
        </p>
        <br /><br />
        <p>
          <span class="text">
            אני החתום מטה מתחייב בזאת להישמע לכול ההוראות והתקנות בנושא משמעת
          </span>
          <br />
          <span class="text">
            וסדרי עבודה אותם קראתי להלן וזאת על פי החלטת רשת רוסמן שיווק
          </span>
        </p>
        <br /><br />
        <p class="text">חתימת העובד על נהלי המשמעת</p>
        <SignaturePad idName="paint3" sketchName="sketch3" />
      </div>
      <!-- נהלי בטיחות -->
      <div class="content before" id="nb">
        <h3
          style="text-align:center; color:red; text-decoration:underline; margin:25px 0;"
          class="text"
        >
          הצהרת בטיחות שיווק רוסמן בע"מ
        </h3>
        <hr />
        <br />
        <p>
          א<span dir="rtl">.</span>&rlm;
          <span class="text"
            >כל עובד חייב להישמע להוראות הבטיחות של החברה ולקיימן</span
          ><span dir="rtl">,</span>&rlm;
          <span class="text">לרבות הוראות הקשורות לתפעול או שימוש במתקנים</span
          ><span dir="rtl">,</span>&rlm; <span>מכונות</span
          ><span dir="rtl">,</span>&rlm;<span>ציוד</span
          ><span dir="rtl">,</span>&rlm; <span>חומרים</span
          ><span dir="rtl">,</span>&rlm;<span>אביזרים</span>
          <span dir="rtl">,</span>&rlm;<span class="text"
            >בגדים ואמצעי מיגון אחרים</span
          >
        </p>
        <br />
        <p>
          ב<span dir="rtl">.</span>&rlm;
          <span class="text"
            >עובד חייב להישמע להוראות ושלטים הפזורים במתקני החברה</span
          >
          <span class="text">לרבות שלטים האוסרים עישון</span
          ><span dir="rtl">.</span>&rlm;
        </p>
        <br />
        <p>
          ג<span dir="rtl">.</span>&rlm;
          <span class="text">במקרה של קלקול או תקלה במכונה</span
          ><span dir="rtl">,</span>&rlm; <span>מתקן</span
          ><span dir="rtl">,</span>&rlm;<span class="text"
            >ציוד וכד' ידווח העובד על כך מיד לממונה עליו</span
          ><span dir="rtl">.</span>&rlm;
          <span class="text">העובד לא ימשיך בעבודתו</span>
          <span class="text">
            ולא יבצע תיקונים על דעת עצמו אלא אם רק הוסמך לכך</span
          ><span dir="rtl">.</span>&rlm;
        </p>
        <br />
        <p style="font-weight:bold;">
          <span class="text">עובד שסופק לו ציוד מגן</span>
          <span dir="rtl">:</span>&rlm;
        </p>
        <br />
        <p>
          ד<span dir="rtl">.</span>&rlm;
          <span class="text"
            >יבדוק לפני השימוש הראשון בכל יום אם ציוד המגן תקין</span
          ><span dir="rtl">.</span>&rlm;
        </p>
        <br />
        <p>
          ה<span dir="rtl">.</span>&rlm;
          <span class="text">ישתמש בציוד מגן להבטחת בטיחותו</span
          ><span dir="rtl">.</span>&rlm;
        </p>
        <br />
        <p>
          ו<span dir="rtl">.</span>&rlm;
          <span class="text">יטפל בציוד המגן וישמור עליו כראוי</span>
          <span dir="rtl">.</span>&rlm;
        </p>
        <br />
        <p>
          ז<span dir="rtl">.</span>&rlm;
          <span class="text">משנתגלה לו שהציוד לקוי</span
          ><span dir="rtl">,</span>&rlm;
          <span class="text">ידווח ויחזירו מיד עם גילוי הדבר לממונה עליו</span>
          <span dir="rtl">.</span>&rlm;
        </p>
        <br /><br />
        <p style="font-weight:bold;">
          <span class="text">עובד שסופק לו ציוד לשם עבודתו</span
          ><span dir="rtl">:</span>&rlm;
        </p>
        <br />
        <p>
          ח<span dir="rtl">.</span>&rlm;
          <span class="text"
            >עובד\ת יבדוק לפני השימוש את צויד לשם עבודתו באופן בטוח</span
          >
          <span dir="rtl">.</span>&rlm;
        </p>
        <br />
        <p>
          ט<span dir="rtl">.</span>&rlm;
          <span class="text">ישתמש בציוד באופן בטוח להבטחת בטיחותו</span>
          <span dir="rtl">.</span>&rlm;
        </p>
        <br />
        <p>
          י<span dir="rtl">.</span>&rlm;
          <span class="text"
            >בעת שימוש במסור חיתוך הבשר חייב להשתמש במגן הבטיחות</span
          >
          <span dir="rtl">.</span>&rlm;
        </p>
        <br />
        <p>
          יא<span dir="rtl">.</span>&rlm;
          <span class="text">עובד\ת במעדנייה יעבוד עם מגן חיתוך</span>
          <span dir="rtl">.</span>&rlm;
        </p>
        <br />
        <p>
          יב<span dir="rtl">.</span>&rlm;
          <span class="text"
            >העובד ישתמש בציוד מגן אישי רק למטרתו ויעדו ולהבטחתו של העובד</span
          >
          <span dir="rtl">.</span>&rlm;
        </p>
        <br /><br />
        <p style="font-weight:bold;" class="text">גהות ובריאות סביבתית</p>
        <br />
        <p>
          יג<span dir="rtl">.</span>&rlm;
          <span class="text"
            >העובד ידאג בזאת לנקות ולסדר את סביבת עבודתו במחלקתו</span
          >
          <span dir="rtl">.</span>&rlm;
        </p>
        <br />
        <p>
          יד<span dir="rtl">.</span>&rlm;
          <span class="text"
            >חומרי ניקיון לא יחסנו במקום שבו נמצא מאוחסן מזון אדם
          </span>
          <span dir="rtl">.</span>&rlm;
        </p>
        <br />
        <p>
          טו<span dir="rtl">.</span>&rlm;
          <span class="text"
            >כל פעולה של ניקוי עם חומרים שונים לבישת כפפות גומי למניעת פגיעת
            עור</span
          >
          <span dir="rtl">.</span>&rlm;
        </p>
        <br /><br />
        <p style="font-weight:bold;">
          <span class="text">עובדי מעדניות וקצבים</span>
        </p>
        <br />
        <p>
          טז<span dir="rtl">.</span>&rlm;
          <span class="text"
            >עובדי מעדניות וקצבים ידאגו כי לא יהיו עליהם במהלך העבודה תכשיטים
            ו/או אביזרים</span
          >
          <br />
          <span class="text">שונים על גופם העלולים לגרום</span>
          <span class="text">לתקלות בטיחות כגון</span
          ><span dir="rtl">:</span>&rlm; <span class="text">שרשרת ארוכה</span
          ><span dir="rtl">,</span>&rlm;<span class="text">טבעות בולטות</span
          ><span dir="rtl">,</span>&rlm;
          <span class="text">שעונים גדולים וכד'</span
          ><span dir="rtl">.</span>&rlm;
        </p>
        <br />
        <p>
          יז<span dir="rtl">.</span>&rlm;
          <span class="text"
            >עובדי מעדניות וקצבים מחויבים לעבוד עם אמצעי המיגון שיסופקו
            להם</span
          >
          <span class="text">על ידי רשת רוסמן כגון כפפת ברזל</span
          ><span dir="rtl">,</span>&rlm; <span class="text">חלוקים וכו</span
          ><span dir="rtl">.</span>&rlm;
        </p>
        <p class="before"></p>
        <p style="margin:25px 0;"></p>
        <p style="font-weight:bold;">קצבים</p>
        <br />
        <p>
          יח<span dir="rtl">.</span>&rlm;
          <span class="text"
            >במהלך העבודה בזמן חיתוך בשר כלשהו חובה לעבוד עם כפפת ברזל תקנית
            שתסופק על ידי החברה</span
          ><span dir="rtl">.</span>&rlm;
          <span class="text"
            >במידה וחסרה במחלקה כפפה שכזו יש לדווח מיידית על כך למנהל/סגן
            הסניף</span
          ><span dir="rtl">.</span>&rlm;
        </p>
        <br />
        <p>
          יט<span dir="rtl">.</span>&rlm;
          <span class="text"
            >במהלך השימוש במשור לחיתוך בשר אסור לעבוד עם כפפת הברזל</span
          ><span dir="rtl">.</span>&rlm;
        </p>
        <br />
        <p>
          כ<span dir="rtl">.</span>&rlm;
          <span class="text">במהלך עבודה עם מטחנת הבשר החשמלית</span>
          <br />
          <span class="text"
            >חובה לעבוד עם דחסן בשר וחל איסור מוחלט להשתמש בידיים</span
          >
          <span class="text">או בכל שיטה אחרת לצורך דחיסת הבשר</span
          ><span dir="rtl">.</span>&rlm;
        </p>
        <br />
        <p>
          כא<span dir="rtl">.</span>&rlm;
          <span class="text"
            >במהלך עבודה עם בשר בקר/לבן ביחד עם בשר עוף יש להקפיד לעבוד עם שתי
            מטחנות</span
          >
          <br />
          <span class="text">בשר ולבצע הפרדה מוחלטת בין סוגי הבשר</span
          ><span dir="rtl">.</span>&rlm;
        </p>
        <br /><br />
        <p style="font-weight:bold;">
          <span>בטיחות</span><span dir="rtl">,</span>&rlm;
          <span class="text">דליקות ואש</span>
        </p>
        <br />
        <p>
          כב<span dir="rtl">.</span>&rlm;
          <span class="text">אין להבעיר אש בשטחי מתקני החברה</span
          ><span dir="rtl">.</span>&rlm;
        </p>
        <br />
        <p>
          כג<span dir="rtl">.</span>&rlm;
          <span class="text"
            >כל עובד חייב להכיר את פתחי המילוט לשעת חירום באתר בו הוא
            מועסק</span
          ><span dir="rtl">.</span>&rlm;
        </p>
        <br />
        <p>
          כד<span dir="rtl">.</span>&rlm;
          <span class="text"
            >כל עובד חייב להכיר את ציוד הכיבוי לרבות מטפים זרנוקים וגלגלונים
            באתר בו הוא מועסק</span
          ><span dir="rtl">.</span>&rlm;
        </p>
        <br />
        <p>
          כה<span dir="rtl">.</span>&rlm;
          <span class="text"
            >אם קיים סיכון לחיי העובד או לבריאותו יש לעזוב את המקום מיד</span
          ><span dir="rtl">.</span>&rlm;
        </p>
        <br />
        <p>
          כו<span dir="rtl">.</span>&rlm;
          <span class="text"
            >על העובד לנהוג על פי ההנחיות של המעביד או לפי ההדרכה שקיבל כיצד
            עליו לפעול בזמן שריפה</span
          ><span dir="rtl">.</span>&rlm;
        </p>
        <br /><br />
        <p style="font-weight:bold;" class="text">בטיחות חשמל</p>
        <br />
        <p>
          כז<span dir="rtl">.</span>&rlm;
          <span class="text">לא ייגע עובד בחשמל לרבות שקעים</span
          ><span dir="rtl">,</span>&rlm; <span>תקעים</span
          ><span dir="rtl">,</span>&rlm; <span class="text">כבלים חשופים</span
          ><span dir="rtl">,</span>&rlm;
          <span class="text">חשמל רופף אלא אם הוסמך לכך</span
          ><span dir="rtl">.</span>&rlm;
        </p>
        <br />
        <p>
          כח<span dir="rtl">.</span>&rlm;
          <span class="text"
            >בכל בעיה או ליקוי במערכות חשמל ידווח העובד מיידית למנהל/סגן הסניף
            להמשך טיפול</span
          ><span dir="rtl">.</span>&rlm;
        </p>
        <br />
        <p>
          כט<span dir="rtl">.</span>&rlm;
          <span class="text"
            >בזמן ניקיונות ושטיפה עם מים יש להקפיד שלא לבוא במגע עם לוחות
            חשמל</span
          >
          <span dir="rtl">,</span>&rlm;
          <span class="text"
            >שקעים תאורה וכל מה שקשור בחשמל בסביבת מקום העבודה</span
          ><span dir="rtl">.</span>&rlm;
        </p>
        <br />
        <p style="font-weight:bold;">
          <span class="text">הצהרת העובד בנושא בטיחות כללית</span
          ><span dir="rtl">,</span>&rlm; <span class="text">אש וחשמל</span
          ><span dir="rtl">:</span>&rlm;
        </p>
        <br />
        <p>
          <span class="text"
            >אני החתום מטה מתחייב בזאת להישמע לכול ההוראות והתקנות בנושא בטיחות
            כללית</span
          ><span dir="rtl">,</span>&rlm; <span class="text">אש וחשמל</span>
          <span class="text">
            אותם קראתי להלן וזאת על פי החלטת רשת רוסמן שיווק</span
          ><span dir="rtl">.</span>&rlm;
        </p>
        <br />
        <p>
          <span class="text">
            יודע אני כי אם הפר את ההוראות הללו ולא אשמע להן רואה זאת החברה כהפרה
            של
          </span>
          <br />
          <span class="text">
            הסכם העבודה וינקטו נגדי צעדים משמעתיים לרבות שימוע והפסקת</span
          >
          <br />
          <span class="text">עבודתי בחברת רוסמן שיווק בע"מ</span>
        </p>
        <br />
        <p>
          <span class="text"
            >כמו כן אני מצהיר שקיבלתי הדרכה בנושאי בטיחות כללית</span
          ><span dir="rtl">,</span>&rlm; <span class="text">אש וחשמל</span>
          <br />
          <span class="text">
            המופיעים בהוראות הרצ"ב ישירות מממונה הבטיחות של החברה</span
          ><span dir="rtl">.</span>&rlm;
        </p>
        <p class="before"></p>
        <p style="margin:25px 0;"></p>
        <p style="font-weight:bold;" class="text">
          חתימת העובד על נהלי הבטיחות
        </p>
        <SignaturePad idName="paint4" sketchName="sketch4" />

        <br />
        <p class="text">
          שם מלא של העובד המעביר את התדריך   
        </p>
        <p v-if="in_process" style="color:red;">{{ values.charge }}</p>
        <InputText
          v-else
          type="text"
          v-model="values.charge"
          :placeholder="values.charge"
          style="width:50%"
        />
        <br /><br />
        <p style="font-weight:bold;" class="text">
          חתימה של העובד המעביר את התדריך
        </p>
        <SignaturePad idName="paint5" sketchName="sketch5" />
      </div>
      <!-- תנאי העסקה -->
      <div class="content before" id="ta">
        <h3
          style="text-align:center; color:red; text-decoration:underline; margin:25px 0;"
          class="text"
        >
          מסמך הודעה בדבר פירוט תנאי עבודה
        </h3>
        <hr />
        <br />
        <p>
          <span class="text">שיווק רוסמן בע"מ</span
          ><span dir="rtl">-</span>&rlm; <span class="text">ח</span
          ><span dir="rtl">.</span>&rlm;<span class="text">פ</span
          ><span dir="rtl">.</span>&rlm; <span class="text">512451451</span
          ><span dir="rtl">,</span>&rlm;
          <span class="text">חלוצי התעשייה 48</span
          ><span dir="rtl">,</span>&rlm; <span class="text">מפרץ חיפה</span
          ><span dir="rtl">.</span>&rlm;
        </p>
        <br />
        <p>
          1<span dir="rtl">.</span>&rlm;
          <span class="text">שם העובד ופרטיו</span><span dir="rtl">:</span>&rlm;
          <span class="text"
            >מולא במסגרת שאר הטפסים בהתאם לפרטים שנמסרו במסגרת טופס דיגיטלי
            זה</span
          ><span dir="rtl">.</span>&rlm;
        </p>
        <br />
        <p>
          2<span dir="rtl">.</span>&rlm;
          <span class="text">תאריך תחילת העבודה</span
          ><span dir="rtl">:</span>&rlm;
          <span class="text"
            >מולא במסגרת שאר הטפסים בהתאם לפרטים שנמסרו במסגרת טופס דיגיטלי
            זה</span
          ><span dir="rtl">.</span>&rlm;
        </p>
        <p class="text">** תקופת החוזה איננה קצובה</p>
        <br />
        <p>
          3<span dir="rtl">.</span>&rlm;
          <span class="text"
            >תפקידיו העיקריים של העובד הם בהתאם לפרטים שנמסרו במסגרת טופס
            דיגיטלי זה</span
          ><span dir="rtl">.</span>&rlm;
        </p>
        <br />
        <p>
          4<span dir="rtl">.</span>&rlm;
          <span class="text"
            >שם הממונה הישיר על העובד או תואר התפקיד של הממונה הישיר הוא מנהל
            הסניף בו הוא התחיל עבודתו</span
          ><span dir="rtl">.</span>&rlm;
        </p>
        <br />
        <p>
          5<span dir="rtl">.</span>&rlm;
          <span class="text">הבסיס שלפיו משולם השכר</span
          ><span dir="rtl">:</span>&rlm;
          <span class="text">שכר שעתי שימולא דיגיטלית במסמך זה</span>
          <span dir="rtl">,</span>&rlm;
          <span class="text">בהתאם להסכם שאושר מול סמנכ"ל תפעול</span
          ><span dir="rtl">.</span>&rlm;
        </p>
        <br />
        <p>
          6<span dir="rtl">.</span>&rlm;
          <span class="text">מוסכם בין הצדדים כי הפרשות החברה על פי הסכם קרן הפנסיה על חשבון פיצויי פיטורים</span>
          <span dir="rtl">,</span>&rlm;
          <span class="text">יבואו במקום חובה החברה לתשלום פיצויי פיטורים, בהתאם לסעיף 14 לחוק פיצויי פיטורים התשכ"ג</span>
          <span dir="rtl">-</span>&rlm;
          <span class="text">1963 זאת בהתאם לאישור הכללי בדבר תשלומי מעבידים לקרן פנסיה ולקופת ביטוח במקום פיצויי פיטורים לפי חוק פיצויי פיטורים התשכ"ג</span>
          <span dir="rtl">.</span>&rlm;
        </p>
        <p class="text">** התשלומים והזכויות ישולמו בהתאם לחוק</p>
        <br />
        <p style="font-weight:bold;" class="text">התחייבויות העובד</p>
        <br />
        <p>
          א<span dir="rtl">.</span>&rlm;
          <span class="text">העובד מתחייב כאמור לפעול בנאמנות ובחריצות </span>
          <br />
          <span class="text"
            >עבור המעסיק וכי פעילות שתביא לנזק או הפסד ו/או נוגעת בניגוד
          </span>
          <br />
          <span class="text"
            >עניינים ו/או חוסר נאמנות דינה כעבירת משמעת חמורה השוללת פיצויי
            פיטורים</span
          ><span dir="rtl">.</span>&rlm;
        </p>
        <br />
        <p>
          ב<span dir="rtl">.</span>&rlm;
          <span class="text">העובד נותן מראש הסכמתו לכל קיזוז בגין קנסות</span
          ><span dir="rtl">,</span>&rlm;
          <span class="text">דמי הודעה מוקדמת ו/או ______________ משכרו</span>
          <span dir="rtl">,</span>&rlm; <span class="text">כולל האחרון</span
          ><span dir="rtl">.</span>&rlm;
        </p>
        <br />
        <p>
          ג<span dir="rtl">.</span>&rlm;
          <span class="text"
            >העובד מתחייב להגיע בזמן לעבודתו ולסיים בשעה המוסכמת לאחר שסיים
            משימותיו</span
          ><span dir="rtl">.</span>&rlm;
        </p>
        <br />
        <p>
          ד<span dir="rtl">.</span>&rlm;
          <span class="text">העובד מתחייב להחתים שעון נוכחות</span
          ><span dir="rtl">.</span>&rlm;
        </p>
        <br />
        <p>
          ה<span dir="rtl">.</span>&rlm;
          <span class="text"
            >העובד מתחייב שלא לשתות במהלך העבודה שתיה אלכוהולית</span
          >
          <br />
          <span class="text">או לעשות שימוש בחומרים נרקוטיים</span
          ><span dir="rtl">.</span>&rlm;
        </p>
        <br />
        <p>
          ו<span dir="rtl">.</span>&rlm;
          <span class="text"
            >העובד מתחייב לספק אישור תעסוקתי מרופא תעסוקתי על כשירותו
          </span>
          <br />
          <span class="text">לעבודה תוך 3 חודשים ממועד תחילת העסקתו</span
          ><span dir="rtl">.</span>&rlm;
        </p>
        <br />
        <p>
          ז<span dir="rtl">.</span>&rlm;
          <span class="text"
            >העובד מתחייב להודיע על כל מחלה זיהומית או כזו שיש בה להשפיע או לסכן
            את מקום העבודה באופן מידי</span
          ><span dir="rtl">.</span>&rlm;
        </p>
        <br />
        <p>
          ח<span dir="rtl">.</span>&rlm;
          <span class="text"
            >העובד מצהיר בזאת כי קיבל הנחיות בטיחות וזהירות וכי הוא מתחייב לנהוג
            על פיהן</span
          ><span dir="rtl">.</span>&rlm;
        </p>
        <br />
        <p>
          ט<span dir="rtl">.</span>&rlm;
          <span class="text"
            >העובד נותן בזאת הסכמתו מראש לבדיקת פוליגרף ע"פ דרישות המעסיק</span
          ><span dir="rtl">.</span>&rlm;
        </p>
        <br />
        <p>
          <span class="text"
            >הפרה של כל אחד מהסעיפים הנ"ל תזכה את המעסיק לפטר את העובד תוך שלילת
            פיצוייו</span
          >
          <br />
          <span class="text">באופן מלא וקיזוז כל נזק שגרם</span
          ><span dir="rtl">.</span>&rlm;
        </p>
        <br />
        <p>
          <span class="text"
            >אין באמור בהודעה זו כדי לגרוע מכל זכות המוקנית לעובד מכח כל
            דין</span
          ><span dir="rtl">,</span>&rlm; <span class="text">צו הרחבה</span
          ><span dir="rtl">,</span>&rlm;
          <br />
          <span class="text">הסכם קיבוצי או חוזה עבודה</span
          ><span dir="rtl">.</span>&rlm;
        </p>
        <p class="before"></p>
        <p style="margin:25px 0;"></p>
        <p><span class="text">שכר שעתי התחלתי</span> <span v-if="in_process" style="color:red;">{{ values.startSalary }} &#8362;</span></p>
        <InputText
          v-if="!in_process"
          type="text"
          v-model="values.startSalary"
          :placeholder="values.startSalary"
          style="width:50%"
        />
        
        <br /><br />

        <p class="text">בנוסף לשכר היסוד השעתי העובד זכאי לתמריצי שכר (תלוי ביצוע/נוכחות) שאינם חלק משכר היסוד</p>
        <p class="text">(אינם חלק מחישוב סוציאלי) והם<span dir="rtl">:</span>&rlm;</p>
        <p>
          <span class="text">תמריץ נוכחות יומי בסך<span dir="rtl">- </span>&rlm;</span>
          <span class="text" v-if="in_process" style="color:red;">{{values.tamriz1}}</span>
           <InputText
              v-else
              type="text"
              v-model="values.tamriz1"
              :placeholder="values.tamriz1"
              style="width:20%"
          />
          &#8362;
        </p>
        <p>
          <span class="text">תמריץ נסיעות יומי בסך<span dir="rtl">- </span>&rlm;</span>
          <span class="text" v-if="in_process" style="color:red;">{{values.tamriz2}}</span>
           <InputText
              v-else
              type="text"
              v-model="values.tamriz2"
              :placeholder="values.tamriz2"
              style="width:20%"
          />
          &#8362;
        </p>
        <p>
          <span class="text">תמריץ מכירות יומי בסך<span dir="rtl">- </span>&rlm;</span>
          <span class="text" v-if="in_process" style="color:red;">{{values.tamriz3}}</span>
           <InputText
              v-else
              type="text"
              v-model="values.tamriz3"
              :placeholder="values.tamriz3"
              style="width:20%"
          />
          &#8362;
        </p>
        <br>
        <p class="text">כלל התמריצים הינם תלוי ביצוע וניתן להפסיקם בהודעה מראש בכל עת <span dir="rtl">.</span>&rlm;</p>
        <br>
        <p>
          <span class="text">קבלת קרן השתלמות מהיום הראשון<span dir="rtl">- </span>&rlm;</span>
          <span class="text" v-if="in_process" style="color:red;">{{values.keren}}</span>
          <Dropdown v-model="values.keren" :options="pensiaOptions" placeholder="בחירה" />
        </p>
        <br>
        <p>
          <span class="text">קבלת משכורת 13 מהיום הראשון<span dir="rtl">- </span>&rlm;</span>
          <span class="text" v-if="in_process" style="color:red;">{{values.salary_13}}</span>
          <Dropdown v-model="values.salary_13" :options="pensiaOptions" placeholder="בחירה" />
        </p>

        <p class="text">חתימת העובד על קבלת נוסח תנאי העסקה</p>
        <SignaturePad idName="paint6" sketchName="sketch6" />
      </div>
      <!-- חתימת מנהל -->
      <div class="content before" id="ms">
        <h3
          style="text-align:center; color:red; text-decoration:underline; margin:25px 0;"
          class="text"
        >
          חתימת המנהל המקבל לעבודה
        </h3>
        <hr />
        <br />
        <p style="font-weight:bold; text-decoration:underline;" class="text">
          הצהרת המנהל
        </p>
        <p style="font-weight:bold;">
          <span class="text"
            >אני המנהל החתום מטה מצהיר בזאת כי בחנתי אישית את המועמד לפי
            קריטריונים
          </span>
          <br />
          <span class="text"
            >מקצועיים בלבד ולפי ראות עיני יש ביכולת המועמד להתאים לתפקיד אליו
            הוא מתקבל</span
          ><span dir="rtl">.</span>&rlm;
        </p>
        <br /><br />
        <p class="text">שם המנהל המקבל לעבודה</p>
        <p v-if="in_process" style="color:red;">{{ values.managerName }}</p>
        <InputText
          v-else
          type="text"
          v-model="values.managerName"
          :placeholder="values.managerName"
          style="width:50%"
        />
        <br /><br />
        <p class="text">חתימת המנהל המקבל את העובד</p>
        <SignaturePad idName="paint7" sketchName="sketch7" />
      </div>
      <!-- תחקיר ביטחוני -->
      <div class="content before" id="tb">
        <h3
          style="text-align:center; color:red; text-decoration:underline; margin:25px 0;"
          class="text"
        >
          תחקיר בטחוני שאלון למועמד
        </h3>
        <hr />
        <br /><br />
        <div class="field" style="margin-top:15px; width: 100%;">
          <p>
            <span class="text">האם עבדת בעבר ברוסמן</span
            ><span dir="rtl">?</span>&rlm;
          </p>
           <p v-if="in_process" style="color:red;">{{ values.workedBeforeInRosman }}</p>
          <Dropdown
            v-else
            v-model="values.workedBeforeInRosman"
            :options="workedBeforeInRosmanOptions"
            :placeholder="values.workedBeforeInRosman"
            style="width:100%"
          />
        </div>
        <div style="margin-top:15px; width: 100%;">
          <p class="text">במידה והמועמד ענה כן בסעיף הקודם</p>
          <p>
            <span class="text">יש לרשום באיזה סניף עבד</span
            ><span dir="rtl">,</span>&rlm;
            <span class="text">באיזה תפקיד ומה היתה סיבת העזיבה</span>
          </p>
          <p v-if="in_process" style="color:red;">{{ values.workedBeforeDetails }}</p>
          <Textarea
            v-else
            rows="5"
            cols="30"
            v-model="values.workedBeforeDetails"
            :placeholder="values.workedBeforeDetails"
            style="width:100%"
          />
        </div>
        <div class="col-2">
          <div class="field">
            <p class="text">שם האב</p>
            <p v-if="in_process" style="color:red;">{{ values.fatherName }}</p>
            <InputText
              v-else
              type="text"
              v-model="values.fatherName"
              :placeholder="values.fatherName"
              style="width:100%;"
            />
          </div>
          <div class="field">
            <p class="text">שם האם</p>
            <p v-if="in_process" style="color:red;">{{ values.motherName }}</p>
            <InputText
              v-else
              type="text"
              v-model="values.motherName"
              :placeholder="values.motherName"
              style="width:100%;"
            />
          </div>
          <div class="field">
            <p class="text">ארץ לידה</p>
            <p v-if="in_process" style="color:red;">{{ values.birthCountry }}</p>
            <InputText
              v-else
              type="text"
              v-model="values.birthCountry"
              :placeholder="values.birthCountry"
              style="width:100%;"
            />
          </div>
        </div>
        <div style="margin-top:15px; width: 100%;">
          <p class="text">מקומות עבודה קודמים</p>
          <p>
            <span class="text">בסעיף זה יש לרשום עד 3 מקומות עבודה אחרונים</span
            ><span dir="rtl">,</span>&rlm; <span class="text">באיזה תפקיד</span
            ><span dir="rtl">,</span>&rlm;
            <span class="text">בין איזה תאריכים וסיבת העזיבה</span>
          </p>
          <p v-if="in_process" style="color:red;">{{ values.jobsPlacesBeforeRosman }}</p>
          <Textarea
            v-else
            rows="5"
            cols="30"
            v-model="values.jobsPlacesBeforeRosman"
            :placeholder="values.jobsPlacesBeforeRosman"
            style="width:100%"
          />
        </div>
        <div style="margin-top:15px; width: 100%;">
          <p class="text">שתייה חריפה</p>
          <p>
            <span class="text">האם שתית שתיה חריפה לפני ובמהלך העבודה</span
            ><span dir="rtl">?</span>&rlm;
            <span class="text">האם לדעתך זה אפשרי</span
            ><span dir="rtl">?</span>&rlm;
          </p>
          <p v-if="in_process" style="color:red;">{{ values.alcohol }}</p>
          <Textarea
            v-else
            rows="5"
            cols="30"
            v-model="values.alcohol"
            :placeholder="values.alcohol"
            style="width:100%"
          />
        </div>
        <p class="before"></p>
        <p style="margin:25px 0;"></p>
        <div style="margin-top:15px; width: 100%;">
          <p class="text">שימוש בסמים</p>
          <p>
            <span class="text">האם השתמשת בסמים קלים/קשים בשנתיים האחרונות</span
            ><span dir="rtl">?</span>&rlm;
            <br />
            <span class="text"
              >האם לדעתך אפשרי להשתמש במהלך עבודתך בבית או במקום העבודה</span
            ><span dir="rtl">?</span>&rlm;
          </p>
          <p v-if="in_process" style="color:red;">{{ values.drugs }}</p>
          <Textarea
            v-else
            rows="5"
            cols="30"
            v-model="values.drugs"
            :placeholder="values.drugs"
            style="width:100%"
          />
        </div>
        <div style="margin-top:15px; width: 100%;">
          <p class="text">הוצאה לפועל</p>
          <p>
            <span class="text"
              >האם נפתח נגדך תיק בהוצאה לפועל או שיש לך חובות כספיים שבגינם
            </span>
            <br />
            <span class="text"
              >הוגבל חשבון הבנק שלך או היו עיקולים והחזרות צ'קים</span
            ><span dir="rtl">?</span>&rlm;
          </p>
          <p v-if="in_process" style="color:red;">{{ values.execution }}</p>
          <Textarea
            v-else
            rows="5"
            cols="30"
            v-model="values.execution"
            :placeholder="values.execution"
            style="width:100%"
          />
        </div>
        <div style="margin-top:15px; width: 100%;">
          <p class="text">עבירות פליליות</p>
          <p>
            <span class="text"
              >האם היית חשוד בעבירה פלילית או מעורב בתקרית כלשהי שבה המשטרה היתה
              מעורבת</span
            ><span dir="rtl">?</span>&rlm;
            <br />
            <span class="text">האם נחקרת או היית עד במשפט</span
            ><span dir="rtl">?</span>&rlm;
          </p>
          <p v-if="in_process" style="color:red;">{{ values.criminalOffenses }}</p>
          <Textarea
            v-else
            rows="5"
            cols="30"
            v-model="values.criminalOffenses"
            :placeholder="values.criminalOffenses"
            style="width:100%"
          />
        </div>
        <div style="margin-top:15px; width: 100%;">
          <p class="text">בריאות ותרופות</p>
          <p>
            <span class="text"
              >האם אתה רגיש לתרופות מסוימות או עברת ניתוחים בעבר</span
            ><span dir="rtl">?</span>&rlm;
            <br />
            <span class="text"
              >האם יש בעיה רפואית שאתה יודע עליה שיכולה להגביל אותך
              בעבודתך</span
            ><span dir="rtl">?</span>&rlm;
          </p>
          <p v-if="in_process" style="color:red;">{{ values.health }}</p>
          <Textarea
            v-else
            rows="5"
            cols="30"
            v-model="values.health"
            :placeholder="values.health"
            style="width:100%"
          />
        </div>
        <p class="before"></p>
        <p style="margin:25px 0;"></p>
        <div style="margin-top:15px; width: 100%;">
          <p class="text">העלאת מסמך רלוונטי המעיד על בריאות המועמד</p>
          <input type="file" style="border:none;" @change="handleHaelthPic" />
        </div>
        <div style="margin-top:15px; width: 100%;">
          <p class="text">הוסף תמונה של המועמד</p>
          <input type="file" style="border:none;" @change="handleWorkerPic" />
        </div>
        <br />
        <p style="font-weight:bold" class="text">
          חתימת המועמד על מסירת הפרטים בתחקיר
        </p>
        <SignaturePad idName="paint8" sketchName="sketch8" />

        <br />
        <p class="text">שם מנהל הסניף המתחקר</p>
        <p v-if="in_process" style="color:red;">{{ values.kabatName }}</p>
        <InputText
          v-else
          type="text"
          v-model="values.kabatName"
          :placeholder="values.kabatName"
          style="width:50%"
        />
        <div style="margin-top:15px; width: 100%;">
          <p class="text">הערות מנהל הסניף המתחקר</p>
          <p v-if="in_process" style="color:red;">{{ values.kabatComments }}</p>
          <Textarea
            v-else
            rows="5"
            cols="30"
            v-model="values.kabatComments"
            :placeholder="values.kabatComments"
            style="width:100%"
          />
        </div>
        <br />
        <p style="font-weight:bold" class="text">חתימת הקב"ט</p>
        <SignaturePad idName="paint9" sketchName="sketch9" />
        <div class="col-2">
          <div class="field">
            <p class="text">תאריך ביצוע תחקיר</p>
            <p v-if="in_process" style="color:red;">{{ new Date(values.dateSecurityInterview).toLocaleDateString('he') }}</p>
            <input
              v-else
              type="date"
              v-model="values.dateSecurityInterview"
              :placeholder="values.dateSecurityInterview"
            />
          </div>
          <div class="field">
            <p class="text">המלצת הקב"ט</p>
            <p v-if="in_process" style="color:red;">{{ values.kabatRecomendation }}</p>
            <Dropdown
              v-else
              v-model="values.kabatRecomendation"
              :options="kabatRecomendationOptions"
              :placeholder="values.kabatRecomendation"
              style="width:100%; height:35px;"
            />
          </div>
        </div>
      </div>
      <div
        style="width:100%; text-align:center;"
        data-html2canvas-ignore="true"
      >
        <Button
          label="סיים"
          class="p-button-success"
          @click="handleSubmit"
          style="width:50%; margin-bottom:2rem;"
        />
      </div>
    </div>
    
    <!-- just for mail -->
    <div class="form-email" id="form-email" v-show="false">
      <table
        style="width:80%; border-collapse: collapse; font-size: 1.1rem; margin: 1rem 0;"
      >
        <tr style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            שם הסניף
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.branche }}
          </td>
        </tr>
        <tr>
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            תפקיד מיועד
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.role }}
          </td>
        </tr>
        <tr style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            תאריך תחילת העבודה
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ new Date(values.startWorkingDate).toLocaleDateString("he") }}
          </td>
        </tr>
        <tr>
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            דרך מי הגיע המועמד
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.hr }}
          </td>
        </tr>
        <tr>
          <th v-if="values.hr=='חבר מביא חבר'" style="border:1px solid #dddddd; text-align:right; padding: 8px;">
               שם החבר הממליץ
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.friend_recommend || 'לא צויין' }}
          </td>
        </tr>
        <tr style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            שם פרטי
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.privateName }}
          </td>
        </tr>
        <tr>
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            שם משפחה
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.lastName }}
          </td>
        </tr>
        <tr style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            סוג האזרחות והמעמד בישראל
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.citizenType }}
          </td>
        </tr>
        <tr
          v-if="values.validityPermission.length > 0"
          style="background-color: #dddddd;"
        >
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            תוקף אישור עבודה בישראל
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ new Date(values.validityPermission).toLocaleDateString("he") }}
          </td>
        </tr>
        <tr>
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            מס' ת.ז/דרכון
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.idNumber }}
          </td>
        </tr>
        <tr style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            האם יש לך קרן פנסיה פעילה?
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.pensia }}
          </td>
        </tr>
        <tr
          v-if="values.signaturePensia.url.length > 0"
          style="background-color: #dddddd;"
        >
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            חתימה על קרן פנסיה פעילה
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            <img :src="values.signaturePensia.url" />
          </td>
        </tr>
        <tr>
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            שם הבנק
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.bankName }}
          </td>
        </tr>
        <tr style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            מס' סניף בנק
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.numberBankBrnache }}
          </td>
        </tr>
        <tr>
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            מס' חשבון בנק
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.bankAccount }}
          </td>
        </tr>
        <tr style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            מצב משפחתי
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.familyStatus }}
          </td>
        </tr>
        <tr>
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            תאריך לידה
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ new Date(values.birthDate).toLocaleDateString("he") }}
          </td>
        </tr>
        <tr
          v-if="values.aliyaDate.length > 0"
          style="background-color: #dddddd;"
        >
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            תאריך עלייה לישראל
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ new Date(values.aliyaDate).toLocaleDateString("he") }}
          </td>
        </tr>
        <tr>
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            רחוב
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.street }}
          </td>
        </tr>
        <tr style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            מס' בית
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.houseNumber }}
          </td>
        </tr>
        <tr>
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            מס' דירה
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.apartmentNumber }}
          </td>
        </tr>
        <tr v-if="values.tadDoar.length > 0" style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            תד דואר
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.tadDoar }}
          </td>
        </tr>
        <tr v-if="values.mikud.length > 0" style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            מיקוד
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.mikud }}
          </td>
        </tr>
        <tr>
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            יישוב
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.city }}
          </td>
        </tr>
        <tr style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            טלפון סלולרי
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.phoneNumber }}
          </td>
        </tr>
        <tr>
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            דוא"ל
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.email }}
          </td>
        </tr>
        <tr style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            הכנסות ממקור נוסף
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.incomingFromOtherPlace }}
          </td>
        </tr>
        <tr
          v-if="values.incomingPlace.length > 0"
          style="background-color: #dddddd;"
        >
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            מקור ההכנסה
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.incomingPlace }}
          </td>
        </tr>
        <tr>
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            קופת חולים
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.kupatHolim }}
          </td>
        </tr>
        <tr style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            ילדים עד גיל 18
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.isChildren }}
          </td>
        </tr>
        <template v-for="(children, index) in values.childrens" :key="index">
          <tr
            v-if="values.isChildren == 'כן'"
            style="background-color: #dddddd;"
          >
            <th
              style="border:1px solid #dddddd; text-align:right; padding: 8px;"
            >
              ילד {{ index + 1 }}
            </th>
            <th
              style="border:1px solid #dddddd; text-align:right; padding: 8px;"
            >
              תאריך לידה
            </th>
          </tr>
          <tr>
            <td
              style="border:1px solid #dddddd; text-align:right; padding: 8px;"
            >
              {{ children.name }}
            </td>
            <td
              style="border:1px solid #dddddd; text-align:right; padding: 8px;"
            >
              {{ new Date(children.birthDate).toLocaleDateString("he") }}
            </td>
          </tr>
        </template>
        <tr v-if="values.isChildren=='כן' && values.familyStatus!='נשוי/אה'" style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            משלם מזונות עבור ילדיו?
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.mezonot }}
          </td>
        </tr>
        <tr v-if="values.isChildren=='כן' && values.familyStatus!='נשוי/אה'">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            האם ילדיך בחזקה הבלעדית?
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.children_owner }}
          </td>
        </tr>
        <tr v-if="values.isChildren=='כן' && values.familyStatus!='נשוי/אה'" style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
           האם מנהל משק בית עם יחיד/ה אחר?
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.house_hold_with_someone }}
          </td>
        </tr>
        <tr>
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            חתימת העובד בדבר פרטים אישיים
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            <img :src="values.signature1.url" />
          </td>
        </tr>
        <tr style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            חתימת העובד על נהלי המשמעת
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            <img :src="values.signature2.url" />
          </td>
        </tr>
        <tr>
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            חתימת העובד על נהלי הבטיחות:
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            <img :src="values.signature3.url" />
          </td>
        </tr>
        <tr style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            שם מלא של נאמן הבטיחות מעביר התדריך:
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.charge }}
          </td>
        </tr>
        <tr>
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            חתימת נאמן הבטיחות בדבר ביצוע ההדרכה לעובד:
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            <img :src="values.signature4.url" />
          </td>
        </tr>
        <tr style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            שכר שעתי התחלתי:
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.startSalary }} &#8362;
          </td>
        </tr>
        <tr>
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
           תמריץ נוכחות יומי
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.tamriz1 }} &#8362;
          </td>
        </tr>
        <tr style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
           תמריץ נסיעות יומי
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.tamriz2 }} &#8362;
          </td>
        </tr>
        <tr>
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
           תמריץ מכירות יומי
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.tamriz3 }} &#8362;
          </td>
        </tr>
        <tr style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            חתימת העובד על קבלת נוסח תנאי העסקה:
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            <img :src="values.signature5.url" />
          </td>
        </tr>
        <tr>
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            שם מנהל המקבל לעבודה:
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.managerName }}
          </td>
        </tr>
        <tr style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            חתימת מנהל המקבל את העובד:
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            <img :src="values.signature6.url" />
          </td>
        </tr>
        <tr>
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            האם עבד פעם ברוסמן
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.workedBeforeInRosman }}
          </td>
        </tr>
        <tr
          v-if="values.workedBeforeInRosman == 'כן'"
          style="background-color: #dddddd;"
        >
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            פרטי עבודתו ברוסמן בעבר
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.workedBeforeInRosmanDetails }}
          </td>
        </tr>
        <tr style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            שם האב
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.fatherName }}
          </td>
        </tr>
        <tr>
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            שם האם
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.motherName }}
          </td>
        </tr>
        <tr style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            ארץ לידה
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.birthCountry }}
          </td>
        </tr>
        <tr style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            מקומות עבודה קודמים
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.jobsPlacesBeforeRosman }}
          </td>
        </tr>
        <tr>
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            שתייה חריפה
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.alcohol }}
          </td>
        </tr>
        <tr style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            שימוש בסמים
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.drugs }}
          </td>
        </tr>
        <tr>
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            הוצאה לפועל
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.execution }}
          </td>
        </tr>
        <tr style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            עבר פלילי
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.criminalOffenses }}
          </td>
        </tr>
        <tr>
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            בריאות ותרופות
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.health }}
          </td>
        </tr>
        <tr style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            חתימת המועמד על מסירת הפרטים בתחקיר:
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            <img :src="values.signature7.url" />
          </td>
        </tr>
        <tr>
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            שם מנהל הסניף המתחקר
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.kabatName }}
          </td>
        </tr>
        <tr style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            הערות מנהל הסניף המתחקר
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.kabatComments }}
          </td>
        </tr>
        <tr>
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            חתימת מנהל הסניף 
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            <img :src="values.signature8.url" />
          </td>
        </tr>
        <tr style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            תאריך ביצוע תחקיר:
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{
              new Date(values.dateSecurityInterview).toLocaleDateString("he")
            }}
          </td>
        </tr>
        <tr>
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            המלצת הקב"ט
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.kabatRecomendation }}
          </td>
        </tr>
        <tr style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            הטופס להורדה
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            <a :href="values.summaryPDF.url" target="_blank">צפה בטופס כ-PDF</a>
          </td>
        </tr>
        <tr>
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            צילום ת.ז חלק א
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            <img :src="values.idFrontPic.url" style="width:200px" />
          </td>
        </tr>
        <tr style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            צילום ת.ז חלק ב
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            <img :src="values.idRarePic.url" style="width:200px" />
          </td>
        </tr>
        <tr>
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            צילום חשבון בנק
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            <img :src="values.bankAccountPic.url" style="width:200px" />
          </td>
        </tr>
      </table>
      <div
        class="gallery-2"
        style="width:80%; margin-top:20px; text-align:center;"
      >
        <h3 style="text-decoration:underline;">
          צילום תמונה של המועמד ו/או בריאות המועמד
        </h3>
        <div
          style="width:100%; display:flex; margin-top:15px; justify-content:center;"
        >
          <a
            v-if="values.haelthPic.url.length > 0"
            :href="values.haelthPic.url"
            target="_blank"
          >
            <img
              :src="values.haelthPic.url"
              style="width:200px; margin-left:15px; cursor:pointer;"
              alt="אני מסמך לחץ עליי"
            />
          </a>
          <a :href="values.workerPic.url" target="_blank">
            <img
              :src="values.workerPic.url"
              style="width:200px; margin-left:15px; cursor:pointer;"
            />
          </a>
        </div>
      </div>
    </div>
    <!-- Loader -->
    <div v-if="isPanding" class="loader">
      <h1>נא להמתין הטופס בתהליך קליטה...</h1>
      <img
        src="https://firebasestorage.googleapis.com/v0/b/rosman2021-d33ae.appspot.com/o/reload%2FSpinner-1s-200px.gif?alt=media&token=d0d63819-2a63-4136-ac63-8ce1c1ce839b"
      />
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { ref } from "@vue/runtime-core";
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import Dropdown from "primevue/dropdown";
import InputNumber from 'primevue/inputnumber';
import html2pdf from "html2pdf.js";
import SignaturePad from "./signature/SignaturePad.vue";
import {alert} from '../../../../Methods/Msgs'
import {projectFirestore,projectFunctions} from '../../../../firebase/config'
import use_klitat_oved_storage from '../../../../Methods/use_klitat_oved_storage'
import {isDrawnOn} from '../../../../Methods/Signature_funcs'

export default {
  props: ["branche"],
  components: { InputText, Dropdown, SignaturePad, Textarea,InputNumber },
  emits:['finish'],
  setup(props,{emit}) {
    const wrapper = ref()
    const rest_days_options = ref([
      'יום א',
      'יום ו',
      'יום שבת'
    ])
    const in_process = ref(false)
    const isPanding = ref(false)
    const { url, uploadImage,filePath } = use_klitat_oved_storage();
    const error = ref("");
    const position = ref("");
    const roleOptions = ref([
      "ניהול סניף",
      "סגנות סניף",
      "א.מעדנייה",
      "מעדנייה",
      "א.קצבייה",
      "קצבייה",
      "פקידות",
      "ירקות",
      "סדרנות",
      "קופה",
      "ק.ראשי/ת",
      "מחסן",
      "ניקיון",
      "שמירה",
      "פליירים"
    ]);
    const hrOptions = ref([]);
    const citizenTypeOptions = ref([
      "אזרחות ישראלית קבועה",
      "תושב ארעי",
      "אשרת עבודה בדרכון"
    ]);
    const pensiaOptions = ref(["כן", "לא"]);
    const bankNameOptions = ref([
      "בנק לאומי 10",
      "בנק הפועלים 12",
      "בנק מזרחי 20",
      "בנק דיסקונט 11",
      "בנק מרכנתיל דיסקונט 17",
      "בנק מרכנתיל דיסקונט לשעבר מוניציפל/דקסיה 68",
      "בנק הבינלאומי 31",
      "בנק מסד 46",
      "בנק הבינלאומי לשעבר בנק אוצר החייל 14",
      "בנק הבינלאומי לשעבר בנק פועלי אגודת ישראל (פאגי) 52",
      "בנק איגוד 13",
      "בנק יהב 4",
      "בנק הדואר 9",
      "בנק ירושלים 54",
      "הבנק הדיגיטלי הראשון 18",
      "סיטי בנק 22"
    ]);
    const familyStatusOptions = ref(["נשוי/אה", "רווק/ה", "גרוש/ה", "אלמן/ה"]);
    const incomingFromOtherPlaceOptions = ref(["כן", "לא"]);
    const kupatHolimOptions = ref([
      "מכבי",
      "מאוחדת",
      "לאומית",
      "כללית",
      "ביטוח אישי לתייר",
      'צה"ל'
    ]);
    const childrenOptions = ref(["כן", "לא"]);
    const workedBeforeInRosmanOptions = ref(["כן", "לא"]);
    const kabatRecomendationOptions = ref([
      "לקבל לעבודה",
      "לקבל על תנאי לתקופה מוגבלת",
      "לא לקבל לעבודה"
    ]);

    const values = ref({
      dateInterview: new Date(),
      branche: props.branche,
      role: "",
      startWorkingDate: "",
      hr: "",
      privateName: "",
      lastName: "",
      citizenType: "",
      validityPermission: "",
      idNumber: "",
      masof_number:undefined,
      pensia: "",
      signaturePensia: {
        url: "",
        filePath: ""
      },
      numberBankBrnache: "",
      bankName: "",
      bankAccount: "",
      familyStatus: "",
      birthDate: "",
      aliyaDate: "",
      street: "",
      houseNumber: "",
      apartmentNumber: "",
      tadDoar: "",
      mikud: "",
      city: "",
      phoneNumber: "",
      email: "",
      incomingFromOtherPlace: "",
      incomingPlace: "",
      kupatHolim: "",
      isChildren: "",
      mezonot:"",
      children_owner:"",
      house_hold_with_someone:"",
      childrens: [{}],
      idFrontPic: {
        file: "",
        url: "",
        filePath: ""
      },
      idRarePic: {
        file: "",
        url: "",
        filePath: ""
      },
      bankAccountPic: {
        file: "",
        url: "",
        filePath: ""
      },
      signature1: {
        url: "",
        filePath: ""
      },
      rest_day:'',
      signature2: {
        url: "",
        filePath: ""
      },
      signature3: {
        url: "",
        filePath: ""
      },
      charge: "",
      signature4: {
        url: "",
        filePath: ""
      },
      startSalary: "",
      tamriz1:'',
      tamriz2:'',
      tamriz3:'',
      keren:'',
      salary_13:'',
      signature5: {
        url: "",
        filePath: ""
      },
      managerName: "",
      signature6: {
        url: "",
        filePath: ""
      },
      summaryPDF: {
        url: "",
        filePath: ""
      },
      workedBeforeInRosman: "",
      workedBeforeInRosmanDetails: "",
      fatherName: "",
      motherName: "",
      birthCountry: "",
      jobsPlacesBeforeRosman: "",
      alcohol: "",
      drugs: "",
      execution: "",
      criminalOffenses: "",
      health: "",
      haelthPic: {
        file: "",
        url: "",
        filePath: ""
      },
      workerPic: {
        file: "",
        url: "",
        filePath: ""
      },
      signature7: {
        url: "",
        filePath: ""
      },
      kabatName: "",
      kabatComments: "",
      signature8: {
        url: "",
        filePath: ""
      },
      dateSecurityInterview: "",
      kabatRecomendation: ""
    });

    const validation = () => {
      for (let key in values.value) {
        if (key == "role") {
          if (values.value[key].length == 0) {
            error.value = "נא למלא תפקיד מיועד";
            return false;
          }
        }
        if (key == "startWorkingDate") {
          if (values.value[key].length == 0) {
            error.value = "נא למלא תאריך תחילת עבודה";
            return false;
          }
        }
        if (key == "hr") {
          if (values.value[key].length == 0) {
            error.value = "נא למלא דרך מי הגיע המועמד";
            return false;
          }
        }
        if (key == "privateName") {
          if (values.value[key].length == 0) {
            error.value = "נא למלא שם פרטי";
            return false;
          }
        }
        if (key == "lastName") {
          if (values.value[key].length == 0) {
            error.value = "נא למלא שם משפחה";
            return false;
          }
        }
        if (key == "citizenType") {
          if (values.value[key].length == 0) {
            error.value = "נא למלא סוג האזרחות והמעמד בישראל";
            return false;
          }
          if (
            values.value[key] == "תושב ארעי" ||
            values.value[key] == "אשרת עבודה בדרכון"
          ) {
            if (values.value["validityPermission"].length == 0) {
              error.value = "נא למלא את תוקף אישור העבודה או התעודה הארעית";
              return false;
            }
          }
        }
        if (key == "idNumber") {
          if (values.value[key].length < 9) {
            error.value = "נא למלא ת.ז/דרכון תקין";
            return false;
          }
        }
        if (key == "masof_number") {
          if (values.value[key] == undefined || values.value[key] == null) {
            error.value = "נא להזין מסוף תזמון שעות";
            return false;
          }
        }
        if (key == "pensia") {
          if (values.value[key].length == 0) {
            error.value = "נא למלא האם יש לך קרן פנסיה פעילה";
            return false;
          }
          if (values.value[key] == "כן") {
            const cnv = document.getElementById('paint1');
            if (!isDrawnOn(cnv)) {
              error.value = "נא לחתום שיש לך קרן פנסיה";
              return false;
            }
          }
        }
        if (key == "bankName") {
          if (values.value[key].length == 0) {
            error.value = "נא למלא את שם הבנק";
            return false;
          }
        }
        if (key == "numberBankBrnache") {
          if (values.value[key].length == 0) {
            error.value = "נא למלא את מס סניף הבנק";
            return false;
          }
        }
        if (key == "bankAccount") {
          if (values.value[key].length == 0) {
            error.value = "נא למלא את מס חשבון הבנק";
            return false;
          }
        }
        if (key == "familyStatus") {
          if (values.value[key].length == 0) {
            error.value = "נא למלא מצב משפחתי";
            return false;
          }
        }
        if (key == "birthDate") {
          if (values.value[key].length == 0) {
            error.value = "נא למלא תאריך לידה";
            return false;
          }
        }
        if (key == "street") {
          if (values.value[key].length == 0) {
            error.value = "נא למלא רחוב";
            return false;
          }
        }
        if (key == "houseNumber") {
          if (values.value[key].length == 0) {
            error.value = "נא למלא מס בית";
            return false;
          }
        }
        if (key == "city") {
          if (values.value[key].length == 0) {
            error.value = "נא למלא יישוב";
            return false;
          }
        }
        if (key == "phoneNumber") {
          if (values.value[key].length != 10) {
            error.value = "נא למלא טלפון סלולרי תקין";
            return false;
          }
        }
        if (key == "incomingFromOtherPlace") {
          if (values.value[key].length == 0) {
            error.value = "נא למלא האם יש לך הכנסות ממקור נוסף";
            return false;
          }
          if (values.value[key] == "כן") {
            if (values.value.incomingPlace.length == 0) {
              error.value = "נא לציין את מקור ההכנסה הנוסף";
              return false;
            }
          }
        }
        if (key == "kupatHolim") {
          if (values.value[key].length == 0) {
            error.value = "נא למלא לאיזה קופת חולים אתה שייך";
            return false;
          }
        }
        if (key == "isChildren") {
          if(values.value.familyStatus!='נשוי/אה' && values.value[key]=='כן'){
            if(!values.value.mezonot){
              error.value = 'עליך להזין את לשונית מזונות!'
              return false
            }
            if(!values.value.children_owner){
              error.value = 'עליך להזין את לשונית ילדיך בחזקה הבלעדית!'
              return false
            }
            if(!values.value.house_hold_with_someone){
              error.value = 'עליך להזין את לשונית משק בית!'
              return false
            }
          }
          if (values.value[key].length == 0) {
            error.value = "נא לציין האם גרים איתך בבית ילדים עד גיל 18";
            return false;
          }
          if (values.value[key] == "כן") {
            if (Object.keys(values.value.childrens[0]).length === 0) {
              error.value = "נא לציין לפחות ילד אחד";
              return false;
            }
          }
        }
        if (key == "idFrontPic") {
          if (values.value[key].file.length == 0) {
            error.value = "חסר צילום של ת.ז וספח";
            return false;
          }
        }
        if (key == "idRarePic") {
          if (values.value[key].file.length == 0) {
            error.value = "חסר צילום של ת.ז צד ב";
            return false;
          }
        }
        if (key == "bankAccountPic") {
          if (values.value[key].file.length == 0) {
            error.value = "חסר צילום של חשבון בנק";
            return false;
          }
        }
        if (key == "signature1") {
          const cnv = document.getElementById('paint2');
          if (!isDrawnOn(cnv)) {
            error.value = "חסרה חתימה בפרטים אישיים";
            return false;
          }
        }
        if(key=='rest_day'){
          if(values.value[key].length == 0){
            error.value = 'עליך למלא את יום מנוחתו של העובד'
            return false
          }
        }
        if (key == "signature2") {
          const cnv = document.getElementById('paint3');
          if (!isDrawnOn(cnv)) {
            error.value = "חסרה חתימה של העובד בנהלי משמעת";
            return false;
          }
        }
        if (key == "signature3") {
          const cnv = document.getElementById('paint4');
          if (!isDrawnOn(cnv)) {
            error.value = "חסרה חתימה העובד על נהלי הבטיחות";
            return false;
          }
        }
        if (key == "charge") {
          if (values.value[key].length == 0) {
            error.value = "נא לציין שם מלא של נאמן הבטיחות המעביר את התדריך";
            return false;
          }
        }
        if (key == "signature4") {
          const cnv = document.getElementById('paint5');
          if (!isDrawnOn(cnv)) {
            error.value = "חסרה חתימת נאמן הבטיחות בדבר ביצוע ההדרכה לעובד";
            return false;
          }
        }
        if (key == "startSalary") {
          if (values.value[key].length == 0) {
            error.value = "נא למלא שכר שעתי התחלתי";
            return false;
          }
        }
        if (key == "tamriz1") {
          if (values.value[key].length == 0) {
            error.value = "נא למלא תמריץ נוכחות יומי";
            return false;
          }
        }
        if (key == "tamriz2") {
          if (values.value[key].length == 0) {
            error.value = "נא למלא תמריץ נסיעות יומי";
            return false;
          }
        }
        if (key == "tamriz3") {
          if (values.value[key].length == 0) {
            error.value = "נא למלא תמריץ מכירות יומי";
            return false;
          }
        }
        if (key == "keren") {
          if (values.value[key].length == 0) {
            error.value = "נא לבחור קרן השתלמות";
            return false;
          }
        }
        if (key == "salary_13") {
          if (values.value[key].length == 0) {
            error.value = "נא לבחור משכורת 13";
            return false;
          }
        }
        if (key == "signature5") {
          const cnv = document.getElementById('paint6');
          if (!isDrawnOn(cnv)) {
            error.value = "חסרה חתימת העובד על קבלת נוסח העסקה";
            return false;
          }
        }
        if (key == "managerName") {
          if (values.value[key].length == 0) {
            error.value = "נא לרשום את שם מנהל המקבל לעבודה";
            return false;
          }
        }
        if (key == "signature6") {
          const cnv = document.getElementById('paint7');
          if (!isDrawnOn(cnv)) {
            error.value = "חסרה חתימת מנהל המקבל את העובד";
            return false;
          }
        }
        if (key == "workedBeforeInRosman") {
          if (values.value[key].length == 0) {
            error.value = "נא למלא האם העובד עבר בעבר ברוסמן";
            return false;
          }
        }
        if (key == "fatherName") {
          if (values.value[key].length == 0) {
            error.value = "נא למלא את שם האב של העובד";
            return false;
          }
        }
        if (key == "motherName") {
          if (values.value[key].length == 0) {
            error.value = "נא למלא את שם האם של העובד";
            return false;
          }
        }
        if (key == "birthCountry") {
          if (values.value[key].length == 0) {
            error.value = "נא למלא את ארץ הלידה של העובד";
            return false;
          }
        }
        if (key == "jobsPlacesBeforeRosman") {
          if (values.value[key].length == 0) {
            error.value = "נא לרשום מקומות עבודה קודמים של העובד";
            return false;
          }
        }
        if (key == "alcohol") {
          if (values.value[key].length == 0) {
            error.value = "נא למלא את שדה שתייה חריפה";
            return false;
          }
        }
        if (key == "drugs") {
          if (values.value[key].length == 0) {
            error.value = "נא למלא את שדה שימוש בסמים";
            return false;
          }
        }
        if (key == "execution") {
          if (values.value[key].length == 0) {
            error.value = "נא למלא את שדה הוצאה לפועל";
            return false;
          }
        }
        if (key == "criminalOffenses") {
          if (values.value[key].length == 0) {
            error.value = "נא למלא את שדה עבירות פליליות";
            return false;
          }
        }
        if (key == "health") {
          if (values.value[key].length == 0) {
            error.value = "נא למלא את שדה בריאות ותרופות";
            return false;
          }
        }
        if (key == "workerPic") {
          if (values.value[key].file.length == 0) {
            error.value = "נא להעלות תמונה של המועמד";
            return false;
          }
        }
        if (key == "signature7") {
          const cnv = document.getElementById('paint8');
          if (!isDrawnOn(cnv)) {
            error.value = "חסרה חתימת המועמד על מסירת הפרטים בתחקיר הביטחוני";
            return false;
          }
        }
        if (key == "kabatName") {
          if (values.value[key].length == 0) {
            error.value = "נא למלא את שם הקבט המתחקר";
            return false;
          }
        }
        if (key == "kabatComments") {
          if (values.value[key].length == 0) {
            error.value = "נא למלא את שדה הערות הקבט המתחקר";
            return false;
          }
        }
        if (key == "signature8") {
          const cnv = document.getElementById('paint9');
          if (!isDrawnOn(cnv)) {
            error.value = "חסרה חתימת הקבט";
            return false;
          }
        }
        if (key == "dateSecurityInterview") {
          if (values.value[key].length == 0) {
            error.value = "נא למלא תאריך ביצוע התחקיר";
            return false;
          }
        }
        if (key == "kabatRecomendation") {
          if (values.value[key].length == 0) {
            error.value = "נא למלא את המלצת הקבט";
            return false;
          }
        }
      }
      return true;
    };

    const handleIdPicPartA = e => {
      const selected = e.target.files[0];
      values.value.idFrontPic.file = selected;
    };
    const handleIdPicPartB = e => {
      const selected = e.target.files[0];
      values.value.idRarePic.file = selected;
    };
    const handleAccountBankPic = e => {
      const selected = e.target.files[0];
      values.value.bankAccountPic.file = selected;
    };
    const handleHaelthPic = (e)=>{
      const selected = e.target.files[0];
      values.value.haelthPic.file=selected
    }
    const handleWorkerPic = (e)=>{
      const selected = e.target.files[0];
      values.value.workerPic.file=selected
    }
    const handleSubmit = async () => {
      // in_process.value = true
      // await convertToPDF()
      if (!validation()) {
        alert('error','בעיה',error.value)
      } else {
        in_process.value = true
        console.log(values.value);
        await finishProcess()
      }
    };
    const signatureStoresToUrl=async()=>{
        if(values.value.pensia=='כן'){
                values.value.signaturePensia.url = await getCanvasUrl('paint1',1)
                values.value.signaturePensia.filePath = filePath.value
            }
            values.value.signature1.url = await getCanvasUrl('paint2',2)
            values.value.signature1.filePath = filePath.value
            values.value.signature2.url = await getCanvasUrl('paint3',3)
            values.value.signature2.filePath = filePath.value
            values.value.signature3.url = await getCanvasUrl('paint4',4)
            values.value.signature3.filePath = filePath.value
            values.value.signature4.url = await getCanvasUrl('paint5',5)
            values.value.signature4.filePath = filePath.value
            values.value.signature5.url = await getCanvasUrl('paint6',6)
            values.value.signature5.filePath = filePath.value
            values.value.signature6.url = await getCanvasUrl('paint7',7)
            values.value.signature6.filePath = filePath.value
            values.value.signature7.url = await getCanvasUrl('paint8',8)
            values.value.signature7.filePath = filePath.value
            values.value.signature8.url = await getCanvasUrl('paint9',9)
            values.value.signature8.filePath = filePath.value
    }
    const storeImages=async()=>{
        if(values.value.haelthPic.file.length!=0){
            await uploadImage(values.value.haelthPic.file,values.value.idNumber,'haelth_pic');
            values.value.haelthPic.url = url.value
            values.value.haelthPic.filePath = filePath.value
            delete values.value.haelthPic.file
        }
        
        await uploadImage(values.value.workerPic.file, values.value.idNumber,'worker_pic');
        values.value.workerPic.url = url.value
        values.value.workerPic.filePath = filePath.value
        delete values.value.workerPic.file

        await uploadImage(values.value.idFrontPic.file,values.value.idNumber,'id_frontPic' );
        values.value.idFrontPic.url = url.value
        values.value.idFrontPic.filePath = filePath.value
        delete values.value.idFrontPic.file

        await uploadImage(values.value.idRarePic.file,values.value.idNumber,'id_rarePic');
        values.value.idRarePic.url = url.value
        values.value.idRarePic.filePath = filePath.value
        delete values.value.idRarePic.file

        await uploadImage(values.value.bankAccountPic.file, values.value.idNumber,'bank_accountPic');
        values.value.bankAccountPic.url = url.value
        values.value.bankAccountPic.filePath = filePath.value
        delete values.value.bankAccountPic.file
    
    }
    const storeInDB=async()=>{
         const doc = projectFirestore
          .collection("Applications")
          .doc("7KmZyi2hObdZuRczUNbr")
          .collection("Klitat_oved")
          .doc();
        await doc.set({ ...values.value, id: doc.id });
    }
    const getCanvasUrl = (id,index) => {
      return new Promise(resolve => {
        const signPadElement = document.getElementById(id);
        signPadElement.toBlob(async function(blob) {
          await uploadImage(blob,values.value.idNumber,`signature-${index}`);
          resolve(url.value);
        });
      });
    };

    function sendEmail() {
        // ["gill@rosman.co.il", "ravit.rosman@gmail.com", "rosman.mate@gmail.com", "mosescorcias@gmail.com"]
          projectFunctions.httpsCallable('send_email')({
              html: document.getElementById("form-email").innerHTML,
              destinations:["rosman.mate@gmail.com","gillevi.rosman@gmail.com","ravit.rosman@gmail.com"],
              from:"נפתח טופס קליטת עובד חדש",
              title:`סניף: ${values.value.branche} תפקיד:${values.value.role} שם העובד:${values.value.privateName} ${values.value.lastName}`,
              attachments: [
                {
                  filename: `${values.value.privateName} ${values.value.lastName}.pdf`,
                  path: values.value.summaryPDF.url
                }
              ]
          })
    }

    const storePDF=async(blob)=>{
       await uploadImage(blob,values.value.idNumber,'summary_pdf');
       values.value.summaryPDF.url = url.value
       values.value.summaryPDF.filePath = filePath.value
    }

    const finishProcess = async()=>{
        isPanding.value = true
        await signatureStoresToUrl()
        await storeImages()
        await convertToPDF()
        sendEmail()
        await storeInDB()
        isPanding.value = false
        alert('success','הצלחה','הטופס נקלט בהצלחה')
        .then(()=>{
          console.log(values.value);
             emit('finish')
        //   router.push({name:'app_klitat_oved'})
        })
    }
    const handleScroll = () => {
      const el = document.querySelector(".container");
      //משמעת
      const mishmaat = document.getElementById("mishmaat");
      //נהלי בטיחות
      const nb = document.getElementById("nb");
      //תנאי העסקה
      const ta = document.getElementById("ta");
      //מנהל חתימה
      const ms = document.getElementById("ms");
      //תחקיר בטחוני
      const tb = document.getElementById("tb");
      if (el.scrollTop < mishmaat.offsetTop) {
        position.value = "pi";
      }
      if (el.scrollTop > mishmaat.offsetTop && el.scrollTop < nb.offsetTop) {
        position.value = "mishmaat";
      }
      if (el.scrollTop > nb.offsetTop && el.scrollTop < ta.offsetTop) {
        position.value = "nb";
      }
      if (el.scrollTop > ta.offsetTop && el.scrollTop < ms.offsetTop) {
        position.value = "ta";
      }
      if (el.scrollTop > ms.offsetTop && el.scrollTop < tb.offsetTop) {
        position.value = "ms";
      }
      if (el.scrollTop > tb.offsetTop) {
        position.value = "tb";
      }
    };
    const handleScrollTo = id => {
      position.value = id;
      const element = document.getElementById(id);
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    };
    const handleAddChildren = () => {
      values.value.childrens.push({});
    };
    const handleDeleteChild = index => {
      if (values.value.childrens.length == 1) {
        values.value.childrens = [{}];
        values.value.isChildren = "לא";
      } else {
        values.value.childrens.splice(index, 1);
      }
    };
    const convertToPDF = async () => {
      const wrapper = document.getElementById("wrapper");
      const texts = document.querySelectorAll(".text");
      texts.forEach(text => {
        text.textContent = text.textContent.replace(/\s/g, "\u00a0");
      });
      const opt = {
        margin: 0,
        filename: `klitat-oved-${8}.pdf`,
        pagebreak:{ mode: '', before: '.before', after: '.after', avoid: '.avoid' },
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { dpi: 192, letterRendering: true },
        jsPDF: {
          unit: "in",
          format: "a4",
          orientation: "portrait",
          compressPDF: true
        }
      };

      const pdf = await html2pdf()
        .set(opt)
        .from(wrapper)
        .save()
        .output("blob");
      await storePDF(pdf)
      var blobUrl = URL.createObjectURL(pdf);
      window.open(blobUrl);
    };

    const get_hr_companies_form_db = async()=>{
       const docs = await projectFirestore.collection('Shivuk').doc('Shivuk').collection('Hr_Companies').get()
       hrOptions.value = docs.docs.map(doc=>doc.data().name)
    }

    const handle_print = async() => {
       const wrapper = document.getElementById("wrapper");
      const texts = document.querySelectorAll(".text");
      texts.forEach(text => {
        text.textContent = text.textContent.replace(/\s/g, "\u00a0");
      });
      const opt = {
        margin: 0,
        filename: `klitat-oved-${8}.pdf`,
        pagebreak:{ mode: '', before: '.before', after: '.after', avoid: '.avoid' },
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { dpi: 192, letterRendering: true },
        jsPDF: {
          unit: "in",
          format: "a4",
          orientation: "portrait",
          compressPDF: true
        }
      };

      await html2pdf()
        .set(opt)
        .from(wrapper)
        .save()
        .output("blob");
    }

    get_hr_companies_form_db()
    
      
  return {
      wrapper,
      handle_print,
      rest_days_options,
      in_process,
      convertToPDF,
      roleOptions,
      values,
      hrOptions,
      citizenTypeOptions,
      pensiaOptions,
      bankNameOptions,
      familyStatusOptions,
      incomingFromOtherPlaceOptions,
      childrenOptions,
      handleAddChildren,
      handleDeleteChild,
      kupatHolimOptions,
      workedBeforeInRosmanOptions,
      kabatRecomendationOptions,
      handleScrollTo,
      position,
      handleScroll,
      handleSubmit,
      handleIdPicPartA,
      handleIdPicPartB,
      handleAccountBankPic,
      handleHaelthPic,
      handleWorkerPic,
      error,
      isPanding
  };
  },
};
</script>

<style scoped>
.container {
  position: relative;
  width: calc(100vw - 5rem);
  margin-right: 5rem;
  height: 100vh;
  min-height: 100vh;
  background-color: #fff;
  overflow: hidden;
  overflow-y: auto;
}
.wrapper {
  width: 210mm;
  height: auto;
  margin: 0 auto;
}
.steps {
  width: 210mm;
  padding: 1.2rem;
  height: 2rem;
  position: fixed;
  top: 0;
  left: calc((100vw - 5rem - 210mm) / 2);
  background-color: #fff;
  cursor: pointer;
  z-index: 10;
}
.header {
  position: relative;
  width: 100%;
  height: auto;
  height: auto;
  padding: 2rem;
}
.content {
  width: 100%;
  padding: 2rem;
}
.field {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
input {
  width: 100%;
  height: 35px;
  border: 0.5px solid lightgrey;
  border-radius: 3px;
}
.rows {
  display: grid;
  width: 100%;
  height: auto;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px;
}
.col-2 {
  display: grid;
  width: 100%;
  height: auto;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5px;
}
.col-3{
  display: grid;
  width: 100%;
  height: auto;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px;
}
.col-4 {
  display: grid;
  width: 100%;
  height: auto;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 5px;
}
.pesonal-details-col-4{
  grid-template-columns: 30% 30% 10% 28% ;
}
.loader{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.loader img{
  width: 40%;
  height: 70%;
}
.tofes-klitat-oved{
  position: absolute;
  top: 70px;
  right: 30px;
  width: max-content;
  height: max-content;
}
.before {
  page-break-before: always;
}
.print-icon{
  font-size: 50px;
  position: absolute;
  top: 50px;
  left: 50px;
  color: rgb(126, 126, 242);
  cursor: pointer;
  user-select: none;
}
</style>
