<template>
   <div class="choose-branch">
    <p>בחר סניף</p>
    <Dropdown
      v-model="selectedBranch"
      :options="branches"
      placeholder="בחר סניף מתוך הרשימה"
      style="width:300px;"
      scrollHeight="260px"
    />
    <Button
      label="המשך למילוי טופס"
      style="width:260px; height:100px; margin:5px; font-size: 2rem;"
      class="p-button-warning"
      @click="handleRedirectToForm"
    />
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import Dropdown from "primevue/dropdown";
import {get_branches_from_db} from '../../../../Methods/rosman_shivuk_funcs'
import swal from "sweetalert";
export default {
    components: {Dropdown},
    emits:['selectedBranch'],
    setup(_,{emit}){
        const branches = ref([]);
        
        const selectedBranch=ref('')
        const handleRedirectToForm=()=>{
         if(!selectedBranch.value){
           swal("שים לב", "עליך לבחור סניף", "error");
         }else{
            emit('selectedBranch',selectedBranch.value)
         }
        }

        onMounted(async()=>{
            branches.value = await get_branches_from_db()
        })
        return{branches,selectedBranch,handleRedirectToForm}
    }
     
}
</script>

<style scoped>
    
.choose-branch {
  color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>